import { useMutation, useQuery } from '@tanstack/react-query';
import { request } from 'utils/request';

export const addAddress = data => request.post('/api/v1/stores', data);
export const getSourceAddress = () => request.get('/api/v1/stores/source-address');
export const getReturnAddress = () => request.get('/api/v1/stores/return-address');
export const getProvinces = () => request.get('/api/v1/provinces');
export const getDistrictsByProvince = data => request.get(`/api/v1/districts-by-province/${data.provinceId}`);
export const getWardsByProvince = data => request.get(`/api/v1/wards-by-district/${data.districtsId}`);
export const getAddressShipDetail = addressId => request.get(`/api/v1/member/default-addresses/${addressId}`);
export const getAddressMember = memberId => request.get(`/api/v1/member/member-addresses/${memberId}`);

// Mutation
export const useGetDistrictsByProvinceMutation = () => useMutation({ mutationFn: getDistrictsByProvince });
export const useGetWardsByProvinceMutation = () => useMutation({ mutationFn: getWardsByProvince });
export const useAddAddressMutation = () => useMutation({ mutationFn: addAddress });

// Query
export const useQueryGetProvinces = (options = {}) => useQuery({ queryKey: ['GET_PROVINCES'], queryFn: () => getProvinces(), ...options });
export const useQueryGetSourceAddress = (options = {}) =>
  useQuery({ queryKey: ['GET_SOURCE_ADDRESS'], queryFn: () => getSourceAddress(), ...options });
export const useQueryGetReturnAddress = (options = {}) =>
  useQuery({ queryKey: ['GET_RETURN_ADDRESS '], queryFn: () => getReturnAddress(), ...options });
export const useQueryGetAddressDetail = (addressId, options = {}) =>
  useQuery({ queryKey: ['GET_ADDRESS_DETAIL', addressId], queryFn: () => getAddressShipDetail(addressId), ...options });
export const useQueryGetAddressMember = (memberId, options = {}) =>
  useQuery({ queryKey: ['GET_ADDRESS_MEMBER', memberId], queryFn: () => getAddressMember(memberId), ...options });
