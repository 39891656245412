import React from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Text,
  Flex,
  Box,
  Image,
} from '@chakra-ui/react';
import { Input } from '@chakra-ui/react';
import capitalize from 'lodash/capitalize';
import { FORMAT_DATE, ModalType, OrderStatusOption } from 'constants/common';
import { formatDate } from 'utils/helpers';
import { formatImageUrl } from 'utils/url';
import { useQueryGetAddressDetail } from 'services/address';
import { CookieStorage } from 'utils/cookie-storage';

const OrderDetailModal = ({ isOpen, onClose, orderDetail }) => {
  const cancelRef = React.useRef();

  const { data: addressDetailData } = useQueryGetAddressDetail(orderDetail?.memberAddressId?._id, {
    enabled: CookieStorage.isAuthenticated() && !!orderDetail?.memberAddressId?._id,
  });

  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onClose(ModalType.Preview);
        }}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent maxW={'800px'}>
          <AlertDialogHeader textTransform="uppercase">Chi tiết đơn hàng</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <label
              style={{
                fontWeight: 600,
              }}
            >
              ID giỏ hàng
            </label>
            <Input value={orderDetail?.cartId} readOnly mb={4} />
            <label
              style={{
                fontWeight: 600,
              }}
            >
              Mã đơn hàng
            </label>
            <Input mb={4} value={orderDetail?.orderNo} readOnly />
            <label
              style={{
                fontWeight: 600,
              }}
            >
              Hội viên
            </label>
            <Input mb={4} value={orderDetail?.member?.username} readOnly />
            <label
              style={{
                fontWeight: 600,
              }}
            >
              Trạng thái đơn hàng
            </label>
            <Input mb={4} value={OrderStatusOption.find(item => item.value === orderDetail?.status)?.label} readOnly />
            <label
              style={{
                fontWeight: 600,
              }}
            >
              Tổng xu quy đổi
            </label>
            <Input mb={4} value={orderDetail?.totalPrice} readOnly />
            <label
              style={{
                fontWeight: 600,
              }}
            >
              Sản phẩm trong giỏ hàng
            </label>
            <Box flex flexDirection={'column'} mb={4}>
              {orderDetail?.products?.map((item, index) => (
                <Box key={index} border={'1px solid #E2E8F0'} display={'flex'} gap={10} rounded={8} p={4} mb={4}>
                  <Box boxSize="80px">
                    {item?.thumbnails?.length ? <Image src={formatImageUrl(item?.thumbnails[0])} boxSize="80px" mb={4} /> : null}
                  </Box>
                  <Flex gap={2} flexDirection={'column'}>
                    <Flex>
                      <Text minWidth={180} isTruncated key={item._id}>
                        Tên: {capitalize(item.name)}
                      </Text>
                      {/* <Flex gap={10} flexWrap="wrap">
                        {item?.productId?.informations?.map(info => (
                          <Flex key={info._id} flexDirection={'column'}>
                            <Flex gap={2}>
                              <Text isTruncated>{item.productId.variantLabel_1 ? item.productId.variantLabel_1 + ':' : ''}</Text>
                              <Text>{info?.color}</Text>
                            </Flex>
                            <Flex gap={2}>
                              <Text isTruncated>{item.productId.variantLabel_2 ? item.productId.variantLabel_2 + ':' : ''}</Text>
                              <Text>{info?.size}</Text>
                            </Flex>
                            <Flex gap={2}>
                              <Text isTruncated>Đã đổi:</Text>
                              <Text>{info?.exchanged}</Text>
                            </Flex>
                            <Flex gap={2}>
                              <Text isTruncated>Còn lại:</Text>
                              <Text>{info?.remaining}</Text>
                            </Flex>
                            <Flex gap={2}>
                              <Text isTruncated>Tổng số:</Text>
                              <Text>{info?.quantity}</Text>
                            </Flex>
                          </Flex>
                        ))}
                      </Flex> */}
                    </Flex>
                    {item.color && (
                      <Text minWidth={100}>
                        {item.productId.variantLabel_1 ? item.productId.variantLabel_1 : ''}: {item.color}
                      </Text>
                    )}
                    {item.size && (
                      <Text minWidth={100}>
                        {item.productId.variantLabel_2 ? item.productId.variantLabel_2 : ''}: {item.size}
                      </Text>
                    )}
                    <Text minWidth={100}>Xu quy đổi: {item.price}</Text>
                    <Text minWidth={100}>Số lượng: {item.quantity}</Text>
                    <Text minWidth={100}>Tổng xu quy đổi: {item.totalPrice}</Text>
                  </Flex>
                </Box>
              ))}
            </Box>
            <label
              style={{
                fontWeight: 600,
              }}
            >
              Ngày tạo
            </label>
            <Input mb={4} value={formatDate(orderDetail?.createdAt, FORMAT_DATE)} readOnly />
            <label
              style={{
                fontWeight: 600,
              }}
            >
              Số điện thoại
            </label>
            <Input mb={4} value={addressDetailData?.data?.phone} readOnly />
            <label
              style={{
                fontWeight: 600,
              }}
            >
              Địa chỉ nhận hàng
            </label>
            <Input
              mb={4}
              value={
                addressDetailData?.data
                  ? `${addressDetailData?.data?.address}, ${addressDetailData?.data?.ward?.ward_name}, ${addressDetailData?.data?.district?.district_name}, ${addressDetailData?.data?.province?.province_name}`
                  : ''
              }
              readOnly
            />
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={() => {
                onClose(ModalType.Preview);
              }}
            >
              Đóng
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default OrderDetailModal;
