import { Box, Table, Tbody, Td, Th, Thead, Tr, Flex, Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react';
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable, createColumnHelper } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { IconButton } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import { ModalType } from 'constants/common';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { FaHistory } from 'react-icons/fa';
import { ImCoinDollar } from 'react-icons/im';
import AuthorizationComponentWrapper from 'components/AuthorizationComponentWrapper/AuthorizationComponentWrapper';
import { useUserState } from 'context/UserContext';

const SizeTable = ({ categorysData, handleShowMemberDetail }) => {
  const [sorting, setSorting] = useState([]);
  const columnHelper = createColumnHelper();
  const history = useHistory();
  const { userInfo } = useUserState();

  const handleViewClick = (idMember, ModalType) => {
    handleShowMemberDetail(idMember, ModalType);
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor('username', {
        header: 'Tên tài khoản',
        cell: info => info.getValue(),
      }),
      columnHelper.accessor('phone', {
        header: 'Số điện thoại',
        cell: info => info.getValue(),
      }),
      columnHelper.accessor('brand', {
        header: 'Nhà cái',
        cell: info => info.getValue() || '--',
      }),
      columnHelper.accessor('level', {
        header: 'Cấp độ (Nhà cái)',
        cell: info => info.getValue() || '--',
      }),
      columnHelper.accessor('kkLevel', {
        header: 'Cấp độ HTTĐ',
        cell: info => info.getValue() || '--',
      }),
      columnHelper.accessor('coin', {
        header: 'Xu',
        cell: info => info.getValue(),
      }),
      columnHelper.accessor('action', {
        header: 'Thao tác',
        cell: info => (
          <Flex alignItems="center" margin={'auto'}>
            <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'ADD_SUBTRACT_COIN_MEMBER'}>
              <IconButton bg="transparent" onClick={() => handleViewClick(info?.row?.original?._id, ModalType.Adjust)}>
                <ImCoinDollar cursor="pointer" boxSize={4} />
              </IconButton>
            </AuthorizationComponentWrapper>
            <Menu>
              {({ isOpen }) => (
                <>
                  <MenuButton bg="transparent" isActive={isOpen} as={IconButton}>
                    <IconButton bg="transparent" onClick={() => handleViewClick(info?.row?.original?._id, ModalType.Block)}>
                      <FaHistory cursor="pointer" boxSize={4} />
                    </IconButton>
                  </MenuButton>
                  <MenuList>
                    <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'VIEW_HISTORY_RECHARGE_BET_MEMBER'}>
                      <MenuItem
                        onClick={() => {
                          history.push(`/admin/members/${info?.row?.original?._id}/histories-coin`);
                        }}
                      >
                        Lịch sử bù/trừ xu
                      </MenuItem>
                    </AuthorizationComponentWrapper>
                    <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'VIEW_HISTORY_COLLECT_COIN_MEMBER'}>
                      <MenuItem
                        onClick={() => {
                          history.push(`/admin/members/${info?.row?.original?._id}/missions`);
                        }}
                      >
                        Lịch sử nhận xu
                      </MenuItem>
                    </AuthorizationComponentWrapper>
                  </MenuList>
                </>
              )}
            </Menu>
            {/* <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'VIEW_HISTORY_COLLECT_COIN_MEMBER'}>
              <IconButton bg="transparent" onClick={() => history.push(`/admin/members/${info?.row?.original?._id}/missions`)}>
                <FaHistory cursor="pointer" boxSize={4} />
              </IconButton>
            </AuthorizationComponentWrapper> */}
          </Flex>
        ),
      }),
    ],
    [categorysData]
  );

  const table = useReactTable({
    data: categorysData || [],
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <Table>
      <Thead>
        {table.getHeaderGroups().map(headerGroup => (
          <Tr key={headerGroup.id}>
            {headerGroup.headers.map(header => (
              <Th key={header.id} w="120px">
                {header.isPlaceholder ? null : (
                  <Box
                    isTruncated
                    cursor={header.column.getCanSort() ? 'pointer' : 'default'}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {{
                      asc: ' 🔼',
                      desc: ' 🔽',
                    }[header.column.getIsSorted()] ?? null}
                  </Box>
                )}
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody>
        {isEmpty(table.getRowModel().rows) ? (
          <Tr>
            <Td textAlign="center" colSpan={7}>
              Không có dữ liệu
            </Td>
          </Tr>
        ) : (
          table.getRowModel().rows.map(row => (
            <Tr key={row.id}>
              {row.getVisibleCells().map(cell => (
                <Td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>
              ))}
            </Tr>
          ))
        )}
      </Tbody>
    </Table>
  );
};

export default SizeTable;
