import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { Card, CardBody, CardHeader, Stack, Text, Box, Heading, StackDivider, Flex, IconButton } from '@chakra-ui/react';
import AuthorizationComponentWrapper from 'components/AuthorizationComponentWrapper/AuthorizationComponentWrapper';
import { ModalType } from 'constants/common';
import { useUserState } from 'context/UserContext';

export default function AddressCard({ title, addressDetail, styleContainer, handleUpdateItem }) {
  const { userInfo } = useUserState();
  return (
    <Card border="1px" borderColor="#E2E8F0" {...styleContainer}>
      <CardHeader>
        <Flex alignItems="center" justifyContent="space-between">
          <Heading size="md">{title}</Heading>
          <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'UPDATE_ADDRESS'}>
            <Flex gap="6px">
              <IconButton
                onClick={() => {
                  handleUpdateItem(addressDetail, ModalType.Add);
                }}
              >
                <EditIcon />
              </IconButton>
              {/* <IconButton>
              <DeleteIcon color="red.500" />
            </IconButton> */}
            </Flex>
          </AuthorizationComponentWrapper>
        </Flex>
      </CardHeader>

      <CardBody>
        <Stack divider={<StackDivider />} spacing="4">
          <Flex alignItems="center">
            <Heading size="xs" textTransform="uppercase" minW="150px">
              Tên
            </Heading>
            <Text fontSize="sm">{addressDetail?.name}</Text>
          </Flex>
          <Flex alignItems="center">
            <Heading size="xs" textTransform="uppercase" minW="150px">
              Số điện thoại
            </Heading>
            <Text fontSize="sm">{addressDetail?.phone}</Text>
          </Flex>
          <Flex alignItems="center">
            <Heading size="xs" textTransform="uppercase" minW="150px">
              Địa chỉ
            </Heading>
            <Text fontSize="sm">
              {`${addressDetail?.address}, ${addressDetail?.ward?.ward_name}, ${addressDetail?.district?.district_name}, ${addressDetail?.province?.province_name}`}
            </Text>
          </Flex>
        </Stack>
      </CardBody>
    </Card>
  );
}
