import React, { useState, useEffect } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Flex,
  Text,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import isEmpty from 'lodash/isEmpty';
import { useHistory, useParams } from 'react-router-dom';
import InputController from 'components/Form/InputController';
import { OrderShipFormValidate } from 'utils/validation';
import NumericInputController from 'components/Form/NumericInputController';
import { toast } from 'components/Toast';
import { ModalType, ROOT_API } from 'constants/common';
import { useCreateShipOrder } from 'services/shipping';
import { useQueryGetAddressDetail } from 'services/address';
import { CookieStorage } from 'utils/cookie-storage';

export default function FormCreateShipOrder({ isOpen, orderDetail, sourceAddressData, returnAddressData, onClose, refetch }) {
  const params = useParams();
  const { id } = params || {};
  const history = useHistory();
  const [files, setFiles] = useState([]);
  const cancelRef = React.useRef();
  const createShipOrderMutation = useCreateShipOrder();
  const { data } = useQueryGetAddressDetail(orderDetail?.memberAddressId?._id, {
    enabled: CookieStorage.isAuthenticated() && !!orderDetail?.memberAddressId?._id,
  });

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { isDirty },
  } = useForm({
    resolver: yupResolver(OrderShipFormValidate),
  });

  useEffect(() => {
    if (!isEmpty(orderDetail)) {
      reset({
        username: orderDetail?.member?.username,
        orderNo: orderDetail?.orderNo,
        productPrice: '',
      });
      setFiles(
        orderDetail?.thumbnails?.map(item => ({
          id: nanoid(),
          preview: ROOT_API + '/' + item,
          path: item,
        }))
      );
    }
  }, [orderDetail]);

  const onSubmit = async dataForm => {
    if (!!id) {
      createShipOrderMutation.mutate(
        { ...dataForm, id },
        {
          onSuccess: res => {
            refetch();
            onClose(ModalType.Preview);
            toast.showMessageSuccess('Cập nhật đơn hàng vận chuyển thành công');
          },
        }
      );

      return;
    }

    createShipOrderMutation.mutate(dataForm, {
      onSuccess: res => {
        refetch();
        toast.showMessageSuccess('Tạo đơn hàng vận chuyển thành công');
        onClose(ModalType.Preview);
      },
    });
  };

  return (
    <AlertDialog
      motionPreset="slideInBottom"
      leastDestructiveRef={cancelRef}
      onClose={() => {
        onClose(ModalType.Preview);
      }}
      isOpen={isOpen}
      isCentered
    >
      <AlertDialogOverlay />
      <AlertDialogContent maxW={'800px'}>
        <AlertDialogHeader textTransform="uppercase">{`${id ? 'Cập nhập' : 'Tạo'} đơn hàng vận chuyển`}</AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>
          <Flex direction="column" pb={'15px'} gap={4}>
            <Flex flexDirection="column">
              <Text fontWeight={700}>Thông tin đơn hàng: </Text>
              <Text>
                {' '}
                Mã đơn hàng: {orderDetail?.orderNo} - Trạng thái đơn hàng: {orderDetail?.status}
              </Text>
            </Flex>
            <Flex flexDirection="column">
              <Text fontWeight={700}>Địa chỉ giao hàng của hội viên : </Text>
              <Text>Số điện thoại : {orderDetail?.memberAddressId?.phone}</Text>
              <Text>Tên: {orderDetail?.memberAddressId?.name}</Text>
              <Text>
                Địa chỉ: {data?.data?.address}, {data?.data?.ward?.ward_name}, {data?.data?.district?.district_name},{' '}
                {data?.data?.province?.province_name}
              </Text>
            </Flex>
            <Flex flexDirection="column">
              <Text fontWeight={700}>Địa chỉ gửi : </Text>
              <Text>Số điện thoại : {sourceAddressData?.phone}</Text>
              <Text>
                {sourceAddressData?.name}, {sourceAddressData?.address}, {sourceAddressData?.ward?.ward_name},{' '}
                {sourceAddressData?.district?.district_name}, {sourceAddressData?.province?.province_name}
              </Text>
            </Flex>
            <Flex flexDirection="column">
              <Text fontWeight={700}>Địa chỉ hoàn trả : </Text>
              <Text>Số điện thoại : {returnAddressData?.phone}</Text>
              <Text>
                {returnAddressData?.name}, {returnAddressData?.address}, {returnAddressData?.ward?.ward_name},{' '}
                {returnAddressData?.district?.district_name}, {returnAddressData?.province?.province_name}
              </Text>
            </Flex>
            <form>
              <InputController control={control} readOnly name="username" label="Tên hội viên" styleContainer={{ pt: '4' }} />
              <InputController control={control} readOnly name="orderNo" label="Mã đơn hàng" styleContainer={{ pt: '4' }} />
              <NumericInputController control={control} name="productPrice" label="Giá sản phẩm" styleContainer={{ pt: '4' }} />
              <InputController type="textarea" control={control} readOnly name="note" label="Ghi chú" styleContainer={{ pt: '4' }} />
            </form>
            <Flex pt={6} alignItems="flex-end" justifyContent="flex-end">
              <Button
                onClick={() => {
                  history.goBack();
                }}
              >
                Hủy
              </Button>
              <Button
                isDisabled={!isDirty}
                colorScheme="blue"
                ml={3}
                isLoading={createShipOrderMutation.isPending || createShipOrderMutation.isPending}
                onClick={handleSubmit(onSubmit)}
              >
                {!!id ? 'Cập nhập ' : 'Tạo'}
              </Button>
            </Flex>
          </Flex>
        </AlertDialogBody>
      </AlertDialogContent>
    </AlertDialog>
  );
}
