import React from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Text,
  Flex,
  Box,
  Card,
  CardBody,
  List,
  ListItem,
} from '@chakra-ui/react';
import { ModalType, SHIP_STATUS } from 'constants/common';
import capitalize from 'lodash/capitalize';

const ShipDetailModal = ({ isOpen, onClose, shipDetailData }) => {
  const cancelRef = React.useRef();
  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onClose(ModalType.Preview);
        }}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent maxW={'800px'}>
          <AlertDialogHeader textTransform="uppercase">Chi tiết đơn hàng vận chuyển</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <Flex direction="column">
              <Card overflowX={{ sm: 'scroll', xl: 'hidden' }} pb="0px" bg="white">
                <CardBody p={0}>
                  <List spacing={3}>
                    <ListItem flex>
                      <Flex gap={10}>
                        <Text fontWeight={600} minW={"150px"}>Mã đơn vận chuyển</Text>
                        <Text>{shipDetailData?.code}</Text>
                      </Flex>
                    </ListItem>
                    <ListItem>
                      <Flex gap={10}>
                        <Text fontWeight={600}  minW={"150px"}>Thông tin đơn hàng</Text>
                        <Flex gap={3} flexDir={'column'}>
                          <Text as='u'>Mã đơn: {shipDetailData?.orderId?.orderNo}</Text>
                          <Flex gap={1}>
                            <Text as='u'>Sản phẩm: </Text>
                            <Flex flexDir={'column'}>
                              {shipDetailData?.orderId?.products?.map(product => (
                                <Text>
                                  {capitalize(product?.name)} - {capitalize(product?.color)} - {capitalize(product?.size)} - x
                                  {capitalize(product?.quantity)} - Xu: {capitalize(product?.price)} - Tổng xu:{' '}
                                  {capitalize(product?.totalPrice)}
                                </Text>
                              ))}
                            </Flex>
                          </Flex>
                          <Text as='u'>Tổng xu quy đổi: {shipDetailData?.orderId?.totalPrice}</Text>
                        </Flex>
                      </Flex>
                    </ListItem>
                    <Flex gap={2}>
                      <Text fontWeight={600}  minW={"150px"}>Tên sản phẩm:</Text>
                      <Text>{shipDetailData?.productName}</Text>
                    </Flex>
                    <Flex gap={2}>
                      <Text fontWeight={600}  minW={"150px"}>Giá sản phẩm: </Text>
                      <Text>{shipDetailData?.productPrice}</Text>
                    </Flex>
                    <Flex gap={2}>
                      <Text fontWeight={600}  minW={"150px"}>COD: </Text>
                      <Text>{shipDetailData?.cod}</Text>
                    </Flex>
                    <Flex gap={2}>
                      <Text fontWeight={600}  minW={"150px"}>Phí COD:</Text>
                      <Text>{shipDetailData?.cod_fee}</Text>{' '}
                    </Flex>
                    <Flex gap={2}>
                      <Text fontWeight={600}  minW={"150px"}>Ưu đãi:</Text>
                      <Text>{shipDetailData?.discount}</Text>
                    </Flex>
                    <Flex gap={2}>
                      <Text fontWeight={600}  minW={"150px"}>Phí vận chuyển:</Text>
                      <Text>{shipDetailData?.freight_fee}</Text>
                    </Flex>
                    <Flex gap={2}>
                      <Text fontWeight={600}  minW={"150px"}>Phí bảo hiểm:</Text>
                      <Text>{shipDetailData?.insurance_fee}</Text>
                    </Flex>
                    <Flex gap={2}>
                      <Text fontWeight={600}  minW={"150px"}>Loại vận chuyển:</Text>
                      <Text>{shipDetailData?.journeyType}</Text>
                    </Flex>
                    <Flex gap={2}>
                      <Text fontWeight={600}  minW={"150px"}>Sửa đổi:</Text>
                      <Text>{shipDetailData?.modified_at}</Text>
                    </Flex>
                    <Flex gap={2}>
                      <Text fontWeight={600}  minW={"150px"}>Phí hoàn trả:</Text>
                      <Text>{shipDetailData?.return_fee}</Text>
                    </Flex>
                    <Flex gap={2}>
                      <Text fontWeight={600}  minW={"150px"}>Tổng phí:</Text>
                      <Text>{shipDetailData?.total_fee}</Text>
                    </Flex>
                    <Flex gap={2}>
                      <Text fontWeight={600}  minW={"150px"}>Phí VAT:</Text>
                      <Text>{shipDetailData?.vat_fee}</Text>
                    </Flex>
                    <Flex flex gap={4}>
                      <Flex gap={10} mb={2}>
                        <Text fontWeight={600}  minW={"150px"}>Địa chỉ</Text>
                      </Flex>
                      <Box>
                        <Flex gap={10} mb={2}>
                          <Text fontWeight={500} minW={"150px"} as='i'>Địa chỉ nhận hàng của người đặt:</Text>
                          <Text>
                            {shipDetailData?.destName} - {shipDetailData?.destPhoneNumber} - {shipDetailData?.destAddress}
                            ,{shipDetailData?.destWard},{shipDetailData?.destDistrict},{shipDetailData?.destCity}
                          </Text>
                        </Flex>
                        <Flex gap={10} mb={2}>
                          <Text fontWeight={500} minW={"150px"} as='i'>Địa chỉ gửi:</Text>
                          <Text>
                            {shipDetailData?.sourceName} - {shipDetailData?.sourcePhoneNumber} -{' '}
                            {shipDetailData?.sourceAddress},{shipDetailData?.sourceWard},{shipDetailData?.sourceDistrict},
                            {shipDetailData?.sourceCity}
                          </Text>
                        </Flex>
                        <Flex gap={10} mb={2}>
                          <Text fontWeight={500} minW={"150px"} as='i'>Địa chỉ hoàn trả:</Text>
                          <Text>
                            {shipDetailData?.returnName} - {shipDetailData?.returnPhoneNumber} -{' '}
                            {shipDetailData?.returnAddress},{shipDetailData?.returnWard},{shipDetailData?.returnDistrict},
                            {shipDetailData?.returnCity}
                          </Text>
                        </Flex>
                      </Box>
                    </Flex>
                    <Flex gap={6} mb={2}>
                      <Text fontWeight={600}  minW={"150px"}>Trạng thái:</Text>
                     <Flex gap={5}>
                     <Text>{shipDetailData?.result === 1 ? 'Đã tạo đơn' : shipDetailData?.result === 2 ? 'Đã hủy' : ''}</Text> - 
                      <Text>{SHIP_STATUS[Number(shipDetailData?.status)]}</Text>
                     </Flex>
                    </Flex>
                  </List>
                </CardBody>
              </Card>
            </Flex>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Flex pt={6} alignItems="flex-end" justifyContent="flex-end">
              <Button
                onClick={() => {
                  onClose(ModalType.Preview);
                }}
              >
                Đóng
              </Button>
            </Flex>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default ShipDetailModal;
