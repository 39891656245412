import { Box, Flex, IconButton, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable, createColumnHelper } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useHistory } from 'react-router-dom';
import { formatDate } from 'utils/helpers';
import { FORMAT_DATE, SHIP_STATUS } from 'constants/common';
import { toast } from 'components/Toast';
import { CiEdit } from 'react-icons/ci';
import { FaRegCommentDots } from 'react-icons/fa';
import { useCancelShipOrderMutation } from 'services/shipping';
import { BiCommentDetail } from 'react-icons/bi';
import AuthorizationComponentWrapper from 'components/AuthorizationComponentWrapper/AuthorizationComponentWrapper';
import { useUserState } from 'context/UserContext';
import ReviewModal from './ReviewModal';

const OrderCompletedTable = ({ productsData, refetch }) => {
  const history = useHistory();
  const [sorting, setSorting] = useState([]);
  const [reviewDetail, setReviewDetail] = useState(false);
  const [isOpenReviewModal, setIsOpenReviewModal] = useState(false);
  const columnHelper = createColumnHelper();
  const { userInfo } = useUserState();

  const columns = useMemo(
    () => [
      columnHelper.accessor('orderId', {
        header: 'Mã đơn hàng',
        cell: info => (
          <Box textTransform="capitalize" isTruncated>
            <Flex flex gap={4}>
              <Text>{info.renderValue()?.orderNo}</Text>
            </Flex>
          </Box>
        ),
      }),
      columnHelper.accessor('memberId', {
        header: 'Tên người đặt',
        cell: info => info.renderValue()?.username,
      }),
      columnHelper.accessor('productName', {
        header: 'Tên sản phẩm',
        cell: info => info.renderValue(),
      }),
      columnHelper.accessor('callback', {
        header: 'Tình trạng đơn',
        cell: info => SHIP_STATUS[info.getValue()?.status] || 'Đang xử lý',
      }),
      columnHelper.accessor('createdAt', {
        header: 'Ngày tạo',
        cell: info => formatDate(info.getValue(), FORMAT_DATE),
      }),
      columnHelper.accessor('action', {
        header: 'Thao tác',
        cell: info => (
          <Flex alignItems="center" gap={1}>
            <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'VIEW_DETAIL_SHIP_ORDER'}>
              <IconButton
                bg="transparent"
                onClick={() => {
                  setReviewDetail(info?.row?.original);
                  setIsOpenReviewModal(true);
                }}
              >
                <FaRegCommentDots cursor="pointer" boxSize={4} />
              </IconButton>
            </AuthorizationComponentWrapper>
          </Flex>
        ),
      }),
    ],
    [productsData]
  );

  const table = useReactTable({
    data: productsData || [],
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <>
      <Table>
        <Thead>
          {table.getHeaderGroups().map(headerGroup => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <Th key={header.id} w="120px">
                  {header.isPlaceholder ? null : (
                    <Box
                      isTruncated
                      cursor={header.column.getCanSort() ? 'pointer' : 'default'}
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {{
                        asc: ' 🔼',
                        desc: ' 🔽',
                      }[header.column.getIsSorted()] ?? null}
                    </Box>
                  )}
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {isEmpty(table.getRowModel().rows) ? (
            <Tr>
              <Td textAlign="center" colSpan={10}>
                Không có dữ liệu
              </Td>
            </Tr>
          ) : (
            table.getRowModel().rows.map(row => (
              <Tr key={row.id}>
                {row.getVisibleCells().map(cell => (
                  <Td key={cell.id} isTruncated>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                ))}
              </Tr>
            ))
          )}
        </Tbody>
      </Table>
      {isOpenReviewModal && (
        <ReviewModal
          isOpen={isOpenReviewModal}
          reviewDetailData={reviewDetail}
          onClose={() => {
            setReviewDetail(null);
            setIsOpenReviewModal(false);
          }}
        />
      )}
    </>
  );
};

export default OrderCompletedTable;
