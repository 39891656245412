import {
  Box,
  Flex,
  FormControl,
  IconButton,
  Switch,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable, createColumnHelper } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { formatDate } from 'utils/helpers';
import OrderDetailModal from './OrderDetailModal';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { MdLocalShipping } from 'react-icons/md';
import { TbArrowsExchange } from 'react-icons/tb';
import FormCreateShipOrder from './FormCreateShipOrder';
import { FORMAT_DATE, IS_VERIFIED_INFO, OrderStatusOption } from 'constants/common';
import { BiCommentDetail } from 'react-icons/bi';
import ShipDetailModal from './ShipDetailModal';
import ChangeStatusOrder from './ChangeStatusOrder';
import AuthorizationComponentWrapper from 'components/AuthorizationComponentWrapper/AuthorizationComponentWrapper';
import { useUserState } from 'context/UserContext';
import { useDeleteOrderMutation } from 'services/shipping';
import { toast } from 'components/Toast';

const OrderTable = ({ orderData, refetch, sourceAddressData, returnAddressData }) => {
  const [sorting, setSorting] = useState([]);
  const columnHelper = createColumnHelper();
  const textColor = useColorModeValue('blue.700', 'white');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpenShipDetail, onOpen: onOpenShipDetail, onClose: onCloseShipDetail } = useDisclosure();
  const { isOpen: isOpenCreateShip, onOpen: onOpenCreateShip, onClose: onCloseCreateShip } = useDisclosure();
  const { isOpen: isOpenStatus, onOpen: onOpenStatus, onClose: onCloseStatus } = useDisclosure();
  const [orderDetail, setOrderDetail] = useState();
  const { userInfo } = useUserState();
  const deleteOrder = useDeleteOrderMutation();

  
  const handleDeleteOrder = async orderNo => {
    const confirmDelete = window.confirm('Bạn có chắc chắn muốn xóa đơn hàng này không?');

    if (confirmDelete) {
      deleteOrder.mutate(
        { orderNo: orderNo },
        {
          onSuccess: () => {
            toast.showMessageSuccess('Xóa đơn hàng thành công');
            refetch?.();
          },
        }
      );
    }
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor('orderNo', {
        header: 'Mã đơn hàng',
        cell: info => <Box textTransform="uppercase">{info.renderValue()}</Box>,
      }),
      columnHelper.accessor('products', {
        header: 'Sản phẩm',
        cell: info => {
          return (
            <Box>
              {info?.row?.original?.products?.map((product, index) => {
                return (
                  <Box key={index}>
                    <Box>
                      <Text display={'inline'} pr={2}>
                        {product?.name}
                      </Text>
                      <Text display={'inline'} color={textColor} fontWeight={600}>
                        x{product?.quantity}
                      </Text>
                    </Box>
                    {product?.productId && (
                      <Box>
                        {product.color && (
                          <Text fontSize="12px">
                            {product.productId?.variantLabel_1 ? product.productId.variantLabel_1 : ''}: {product.color}
                          </Text>
                        )}
                        {product.size && (
                          <Text fontSize="12px">
                            {product.productId?.variantLabel_2 ? product.productId.variantLabel_2 : ''}: {product.size}
                          </Text>
                        )}
                      </Box>
                    )}
                  </Box>
                );
              })}
            </Box>
          );
        },
      }),
      columnHelper.accessor('member', {
        header: 'Hội viên',
        cell: info => info.renderValue()?.username,
      }),
      columnHelper.accessor('totalPrice', {
        header: 'Tổng giá trị',
        cell: info => info.renderValue(),
      }),
      columnHelper.accessor('status', {
        header: 'Trạng thái',
        cell: info => OrderStatusOption.find(item => item.value === info.getValue())?.label,
      }),
      columnHelper.accessor('createdAt', {
        header: 'Ngày tạo',
        cell: info => formatDate(info.getValue(), FORMAT_DATE),
      }),
      columnHelper.accessor('action', {
        header: 'Thao tác',
        cell: info => (
          <Flex alignItems="center" gap={1}>
            <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'CHANGE_STATUS_ORDER'}>
              <IconButton
                bg="transparent"
                onClick={() => {
                  setOrderDetail(info?.row?.original);
                  onOpenStatus();
                }}
              >
                <TbArrowsExchange cursor="pointer" boxSize={4} />
              </IconButton>
            </AuthorizationComponentWrapper>
            <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'VIEW_DETAIL_ORDER'}>
              <IconButton
                bg="transparent"
                onClick={() => {
                  setOrderDetail(info?.row?.original);
                  onOpen();
                }}
              >
                <EditIcon cursor="pointer" boxSize={4} />
              </IconButton>
            </AuthorizationComponentWrapper>
            <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'CREATE_SHIP_ORDER'}>
              <IconButton
                bg="transparent"
                onClick={() => {
                  setOrderDetail(info?.row?.original);
                  onOpenCreateShip();
                }}
              >
                <MdLocalShipping color="red.400" boxSize={4} />
              </IconButton>
            </AuthorizationComponentWrapper>
            <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'VIEW_DETAIL_SHIP_OF_ORDER'}>
              {!!info?.row?.original?.shipId && (
                <IconButton
                  bg="transparent"
                  onClick={() => {
                    setOrderDetail(info?.row?.original);
                    onOpenShipDetail();
                  }}
                >
                  <BiCommentDetail color="red.400" boxSize={4} />
                </IconButton>
              )}
            </AuthorizationComponentWrapper>
            {
              info?.row?.original?.status === IS_VERIFIED_INFO.REJECTED &&
              <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'DELETE_ORDER'}>
                  <IconButton
                    bg="transparent"
                    onClick={() => {
                      handleDeleteOrder(info?.row?.original?.orderNo);
                    }}
                  >
                    <DeleteIcon color="red.400" boxSize={4} />
                  </IconButton>
              </AuthorizationComponentWrapper>
            }
          </Flex>
        ),
      }),
    ],
    [orderData]
  );

  const table = useReactTable({
    data: orderData || [],
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <Table>
      <Thead>
        {table.getHeaderGroups().map(headerGroup => (
          <Tr key={headerGroup.id}>
            {headerGroup.headers.map(header => (
              <Th key={header.id} w="120px">
                {header.isPlaceholder ? null : (
                  <Box
                    isTruncated
                    cursor={header.column.getCanSort() ? 'pointer' : 'default'}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {{
                      asc: ' 🔼',
                      desc: ' 🔽',
                    }[header.column.getIsSorted()] ?? null}
                  </Box>
                )}
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody>
        {isEmpty(table.getRowModel().rows) ? (
          <Tr>
            <Td textAlign="center" colSpan={10}>
              Không có dữ liệu
            </Td>
          </Tr>
        ) : (
          table.getRowModel().rows.map(row => (
            <Tr key={row.id}>
              {row.getVisibleCells().map(cell => (
                <Td key={cell.id} isTruncated>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Td>
              ))}
            </Tr>
          ))
        )}
      </Tbody>
      {isOpen && <OrderDetailModal isOpen={isOpen} orderDetail={orderDetail} onClose={onClose} />}
      <ShipDetailModal isOpen={isOpenShipDetail} shipDetailData={orderDetail?.shipId} onClose={onCloseShipDetail} />
      <FormCreateShipOrder
        sourceAddressData={sourceAddressData?.data}
        returnAddressData={returnAddressData?.data}
        isOpen={isOpenCreateShip}
        orderDetail={orderDetail}
        onClose={onCloseCreateShip}
        refetch={refetch}
      />
      <ChangeStatusOrder isOpen={isOpenStatus} orderDetail={orderDetail} onClose={onCloseStatus} refetch={refetch} />
    </Table>
  );
};

export default OrderTable;
