import { Box, Flex, FormControl, IconButton, Switch, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable, createColumnHelper } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import isEmpty from 'lodash/isEmpty';
import { useHistory } from 'react-router-dom';
import { formatDate } from 'utils/helpers';
import { FORMAT_DATE, PostType, PostTypeOption } from 'constants/common';
import { toast } from 'components/Toast';
import { useChangeStatusContentMutation, useDeleteSystemContent } from 'services/system-content';
import AuthorizationComponentWrapper from 'components/AuthorizationComponentWrapper/AuthorizationComponentWrapper';
import { useUserState } from 'context/UserContext';

const PostTable = ({ postsData, refetch }) => {
  const history = useHistory();
  const [sorting, setSorting] = useState([]);
  const columnHelper = createColumnHelper();
  const { userInfo } = useUserState();

  const changeStatusMutation = useChangeStatusContentMutation();
  const deleteContentMutation = useDeleteSystemContent();

  const handleSwitchChange = product => {
    changeStatusMutation.mutate(
      { id: product?._id },
      {
        onSuccess: () => {
          toast.showMessageSuccess('Đã thay đổi trạng thái xuất bản nội dung');
          refetch?.();
        },
      }
    );
  };

  const handleDeleteMission = async mission => {
    const confirmDelete = window.confirm('Bạn có chắc chắn muốn xóa nội dung này không?');

    if (confirmDelete) {
      deleteContentMutation.mutate(
        { id: mission?._id },
        {
          onSuccess: () => {
            toast.showMessageSuccess('Xóa nội dung thành công');
            refetch?.();
          },
        }
      );
    }
  };

  const getNamePermissionTypePost = type => {
    switch (type) {
      case PostType.Banner:
        return 'UPDATE_BANNER';
      case PostType.Community:
        return 'UPDATE_POLICY';
      case PostType.Rules:
        return 'UPDATE_RULES';
      case PostType.Terms:
        return 'UPDATE_TERMS';
    }
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor('type', {
        header: 'Loại',
        cell: info => PostTypeOption?.find(item => item.value === info.renderValue()).label,
      }),
      columnHelper.accessor('title', {
        header: 'Tiêu đề',
        cell: info => <Box textTransform="capitalize">{info.renderValue() || '--'}</Box>,
      }),
      columnHelper.accessor('order', {
        header: 'Thứ tự',
        cell: info => info.renderValue() || '--',
      }),
      columnHelper.accessor('author', {
        header: 'Người tạo',
        cell: info => info.renderValue() || '--',
      }),
      columnHelper.accessor('isShow', {
        header: 'Trạng thái xuất bản',
        cell: info => (
          <FormControl display="flex" alignItems="center">
            <Switch isChecked={info?.row?.original?.isShow} onChange={() => handleSwitchChange(info.row.original)} />
          </FormControl>
        ),
      }),
      columnHelper.accessor('createdAt', {
        header: 'Ngày tạo',
        cell: info => formatDate(info.getValue(), FORMAT_DATE),
      }),
      columnHelper.accessor('action', {
        header: 'Thao tác',
        cell: info => (
          <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={getNamePermissionTypePost(info?.row?.original?.type)}>
            <Flex alignItems="center" gap={1}>
              <IconButton
                bg="transparent"
                onClick={() => {
                  history.push(`/admin/setting-system/${info?.row?.original?._id}`);
                }}
              >
                <EditIcon cursor="pointer" boxSize={4} />
              </IconButton>
              <IconButton
                bg="transparent"
                onClick={() => {
                  handleDeleteMission(info?.row?.original);
                }}
              >
                <DeleteIcon color="red.400" boxSize={4} />
              </IconButton>
            </Flex>
          </AuthorizationComponentWrapper>
        ),
      }),
    ],
    [postsData]
  );

  const table = useReactTable({
    data: postsData || [],
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <Table>
      <Thead>
        {table.getHeaderGroups().map(headerGroup => (
          <Tr key={headerGroup.id}>
            {headerGroup.headers.map(header => (
              <Th key={header.id} w="120px">
                {header.isPlaceholder ? null : (
                  <Box
                    isTruncated
                    cursor={header.column.getCanSort() ? 'pointer' : 'default'}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {{
                      asc: ' 🔼',
                      desc: ' 🔽',
                    }[header.column.getIsSorted()] ?? null}
                  </Box>
                )}
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody>
        {isEmpty(table.getRowModel().rows) ? (
          <Tr>
            <Td textAlign="center" colSpan={7}>
              Không có dữ liệu
            </Td>
          </Tr>
        ) : (
          table.getRowModel().rows.map(row => (
            <Tr key={row.id}>
              {row.getVisibleCells().map(cell => (
                <Td key={cell.id} isTruncated>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Td>
              ))}
            </Tr>
          ))
        )}
      </Tbody>
    </Table>
  );
};

export default PostTable;
