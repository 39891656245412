import { useState } from 'react';
import { Button, Flex, Stack, Text, Switch } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import { useHistory } from 'react-router-dom';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import Pagination from 'components/Pagination/Pagination';
import { useQueryGetSystemContent } from 'services/system-content';
import PostTable from './components/Table';

export default function Post() {
  const history = useHistory();
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const { data: systemContent, refetch } = useQueryGetSystemContent(filter);

  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px', lg: '100px' }}>
      <Card p="16px" mb="24px" bg="#fff">
        <CardHeader p="12px 5px" mb="12px">
          <Flex justifyContent={'space-between'}>
            <Flex direction={'column'}>
              <Text fontSize="xl" fontWeight="bold">
                Nội dung hệ thống
              </Text>
            </Flex>
            <Flex gap={2}>
              <Button
                bg="#3182ce"
                color="#fff"
                _hover={{ bg: '#67a1d7' }}
                onClick={() => {
                  history.push('/admin/setting-system/create');
                }}
              >
                <Text fontSize="md" fontWeight="bold" cursor="pointer">
                  Thêm
                </Text>
              </Button>
            </Flex>
          </Flex>
        </CardHeader>
        <CardBody overflowX="auto">
          <Stack overflow={'auto'}>
            <PostTable postsData={systemContent?.data || []} refetch={refetch} />
          </Stack>
          <Flex justifyContent={'flex-end'}>
            {!isEmpty(systemContent?.data) && (
              <Pagination
                page={systemContent?.pagination?.page}
                pageLength={systemContent?.pagination?.pageSize}
                totalRecords={systemContent?.pagination?.count}
                onPageChange={(page, pageLength) => {
                  setFilter({
                    ...filter,
                    pageSize: pageLength,
                    pageIndex: page - 1,
                  });
                }}
              />
            )}
          </Flex>
        </CardBody>
      </Card>
    </Flex>
  );
}
