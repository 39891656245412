import React from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Image,
  Text,
  Flex,
  Box,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
} from '@chakra-ui/react';
import { Input } from '@chakra-ui/react';
import { FORMAT_DATE, ModalType, ROOT_API } from 'constants/common';
import { formatDate } from 'utils/helpers';
import { formatImageUrl } from 'utils/url';

const DetailProductModal = ({ isOpen, onClose, productDetail }) => {
  const cancelRef = React.useRef();

  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onClose(ModalType.Preview);
        }}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent maxW={'100%'} maxH={'400pxs'}>
          <AlertDialogHeader textTransform="uppercase">Chi tiết sản phẩm</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            {productDetail?.thumbnails.length > 0 && (
              <>
                <label
                  style={{
                    fontWeight: 600,
                  }}
                >
                  Hình ảnh sản phẩm
                </label>
                <Image src={formatImageUrl(productDetail?.thumbnails[0])} boxSize="100px" mb={4} />
              </>
            )}
            <label
              style={{
                fontWeight: 600,
              }}
            >
              Tên sản phẩm
            </label>
            <Input value={productDetail?.name} readOnly mb={4} />
            <label
              style={{
                fontWeight: 600,
              }}
            >
              Xu quy đổi
            </label>
            <Input mb={4} value={productDetail?.price} readOnly />
            <label
              style={{
                fontWeight: 600,
              }}
            >
              Danh mục
            </label>
            <Input mb={4} value={productDetail?.categoryId?.title} readOnly />
            <label
              style={{
                fontWeight: 600,
              }}
            >
              Tags
            </label>
            <Input mb={4} value={productDetail?.tags} readOnly />
            <label
              style={{
                fontWeight: 600,
              }}
            >
              Biến thể sản phẩm
            </label>
            <TableContainer my={6}>
              <Table size="sm">
                <Thead>
                  <Tr>
                    <Th>{productDetail?.variantLabel_1}</Th>
                    <Th>{productDetail?.variantLabel_2}</Th>
                    <Th isNumeric>Số lượng đã đổi</Th>
                    <Th isNumeric>Số lượng còn lại</Th>
                    <Th isNumeric>Tổng số lượng</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {productDetail?.informations?.map((item, index) => (
                    <Tr key={index}>
                      <Td>{item?.color}</Td>
                      <Td>{item?.size}</Td>
                      <Td isNumeric>{item?.exchanged}</Td>
                      <Td isNumeric>{item?.remaining}</Td>
                      <Td isNumeric>{item?.quantity}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
            <label
              style={{
                fontWeight: 600,
              }}
            >
              Thông số sản phẩm
            </label>
            <Box flex flexDirection={'column'} mb={4}>
              {productDetail?.metaData?.map(item => (
                <Flex key={item._id}>
                  <Text minW={'150px'}>{item.key}</Text>
                  <Text>{item.value}</Text>
                </Flex>
              ))}
            </Box>
            <label
              style={{
                fontWeight: 600,
              }}
            >
              Mô tả
            </label>
            <Text mb={4}>{productDetail?.description}</Text>
            <label
              style={{
                fontWeight: 600,
              }}
            >
              Coin
            </label>
            <Input mb={4} value={productDetail?.coin} readOnly />
            <label
              style={{
                fontWeight: 600,
              }}
            >
              Số lượng đã đổi
            </label>
            <Input mb={4} value={productDetail?.exchangedQuantity} readOnly />
            <label
              style={{
                fontWeight: 600,
              }}
            >
              Số lượng còn lại
            </label>
            <Input mb={4} value={productDetail?.remainingQuantity} readOnly />
            <label
              style={{
                fontWeight: 600,
              }}
            >
              Tổng số lượng
            </label>
            <Input mb={4} value={productDetail?.totalQuantity} readOnly />
            <label
              style={{
                fontWeight: 600,
              }}
            >
              Ngày tạo
            </label>
            <Input mb={4} value={formatDate(productDetail?.createdAt, FORMAT_DATE)} readOnly />
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={() => {
                onClose(ModalType.Preview);
              }}
            >
              Đóng
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default DetailProductModal;
