import React, { useState, useEffect, useRef } from 'react';
import { Button, FormLabel, FormControl, Flex, Text, Box, Grid, GridItem, IconButton, Image, Switch, Stack } from '@chakra-ui/react';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import { useHistory, useParams } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { nanoid } from 'nanoid';
import { BsUpload, BsXCircle } from 'react-icons/bs';
import { CloseIcon } from '@chakra-ui/icons';
import SelectController from 'components/Form/SelectController';
import InputController from 'components/Form/InputController';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import {
  uploadPhotoProduct,
  useChangeStatusProductMutation,
  useChangeStatusSoldoutMutation,
  useCreateProductMutation,
  useQueryGetCatForProduct,
  useQueryGetProductDetail,
  useUpdateProductMutation,
} from 'services/product';
import { mappingOptionSelect } from 'utils/mapping';
import { ProductFormValidate } from 'utils/validation';
import NumericInputController from 'components/Form/NumericInputController';
import { toast } from 'components/Toast';
import { ROOT_API } from 'constants/common';
import CheckboxController from 'components/Form/CheckboxController';
import { values } from 'lodash';
import { ErrorForm } from 'constants/error';

export default function ProductForm() {
  const params = useParams();
  const { id } = params || {};
  const history = useHistory();
  const [categoryOption, setCategoryOption] = useState([]);
  const [error, setError] = useState({
    thumbnailFile: null,
  });
  const [files, setFiles] = useState([]);
  const [isDragEnter, setIsDragEnter] = useState(false);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': [],
    },
    onDragEnter: () => {
      setIsDragEnter(true);
    },
    onDragLeave: () => {
      setIsDragEnter(false);
    },
    onDrop: acceptedFiles => {
      setIsDragEnter(false);
      setError({ thumbnailFile: null });
      setFiles(prev => [
        ...prev,
        ...acceptedFiles.map(file => ({
          id: nanoid(),
          preview: URL.createObjectURL(file),
          file,
        })),
      ]);
    },
  });

  const { data: productDetailData, refetch } = useQueryGetProductDetail(id, {
    enabled: !!id,
  });
  const { data: categoriesData } = useQueryGetCatForProduct();
  const createProductMutation = useCreateProductMutation();
  const updateProductMutation = useUpdateProductMutation();
  const changeStatusProductMutation = useChangeStatusProductMutation();
  const changeStatusSoldoutProductMutation = useChangeStatusSoldoutMutation();

  const {
    control,
    handleSubmit,
    reset,
    setError: setErrorField,
    formState: { errors },
    trigger,
    clearErrors,
  } = useForm({
    resolver: yupResolver(ProductFormValidate),
    defaultValues: {
      name: '',
      price: '',
      description: '',
      // sale: '',
      tags: '',
      category: undefined,
      // size: undefined,
      // colors: undefined,
      // allowAddText: false,
      informations: [{ size: '', color: '', quantity: '' }],
      metaData: [{ key: '', value: '' }],
      variantLabel_1: '',
      variantLabel_2: '',
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'informations',
  });
  const { fields: fieldsMetaData, append: appendMetaData, remove: removeMetaData } = useFieldArray({
    control,
    name: 'metaData',
  });

  useEffect(() => {
    if (!isEmpty(categoriesData?.data)) {
      setCategoryOption(mappingOptionSelect(categoriesData?.data, 'title'));
    }
  }, [categoriesData?.data]);

  useEffect(() => {
    const productDetail = productDetailData?.data;

    if (!isEmpty(productDetail)) {
      reset({
        name: productDetail?.name,
        price: productDetail?.price,
        tags: productDetail?.tags,
        description: productDetail?.description,
        category: categoryOption?.find(item => item.value === productDetail?.categoryId?._id),
        totalQuantity: productDetail?.totalQuantity,
        informations: productDetail?.informations,
        metaData: productDetail?.metaData,
        variantLabel_1: productDetail?.variantLabel_1,
        variantLabel_2: productDetail?.variantLabel_2,
      });
      setFiles(
        productDetail.thumbnails.map(item => ({
          id: nanoid(),
          preview: ROOT_API + '/' + item,
          path: item,
        }))
      );
    }
  }, [productDetailData, categoryOption]);

  const onUploadPhoto = async () => {
    const formData = new FormData();
    const filesUpload = files.filter(item => item.file);
    const filesExist = files.filter(item => !item.file).map(item => item.path);
    let pathFiles = [];

    if (!isEmpty(filesUpload)) {
      filesUpload.map(item => {
        formData.append('thumbnailFiles', item.file);
      });

      const response = await uploadPhotoProduct(formData);

      pathFiles = response?.data;
    }

    return [...filesExist, ...pathFiles];
  };

  const handleValidateVariantLabel = (infoData, value) => {
    infoData?.map((item, index) => {
      if (!item?.[value]) {
        setErrorField(`informations.${index}.${value}`, { type: 'required', message: ErrorForm.Required });
      }
    });
  };

  const clearValidateVariantLabel = (infoData, value) => {
    infoData?.map((_, index) => {
      clearErrors(`informations.${index}.${value}`);
    });
  };

  const onSubmit = async dataForm => {
    if (isEmpty(files)) {
      setError({ thumbnailFile: 'Tải lên ít nhất 1 hình ảnh' });
      return;
    }

    if (dataForm?.variantLabel_1) {
      const isError = dataForm?.informations?.find(item => !item?.color);

      if (isError) {
        handleValidateVariantLabel(dataForm?.informations, 'color');
        return;
      }
    } else {
      clearValidateVariantLabel(dataForm?.informations, 'color');
    }

    if (dataForm?.variantLabel_2) {
      const isError = dataForm?.informations?.find(item => !item?.size);

      if (isError) {
        handleValidateVariantLabel(dataForm?.informations, 'size');
        return;
      }
    } else {
      clearValidateVariantLabel(dataForm?.informations, 'size');
    }

    const photosPath = await onUploadPhoto();

    let total = 0;
    if (dataForm.informations?.length > 0) {
      total = dataForm.informations?.map(item => item.quantity)?.reduce((prev, next) => Number(prev) + Number(next), 0);
      if (total !== Number(dataForm.totalQuantity)) {
        toast.showMessageError('Số lượng không bằng với tổng số lượng các loại của sản phẩm');
        return;
      }
    } else {
      total = dataForm.totalQuantity;
    }

    const dataSubmit = omit(
      {
        ...dataForm,
        categoryId: dataForm.category?.value,
        colors: dataForm.colors?.map(item => item.value),
        thumbnails: photosPath,
        totalQuantity: total,
      },
      ['category']
    );

    if (!!id) {
      updateProductMutation.mutate(
        { ...dataSubmit, id },
        {
          onSuccess: () => {
            refetch();
            toast.showMessageSuccess('Cập nhật sản phẩm thành công');
            history.push('/admin/product');
          },
          onError: () => {
            toast.showMessageError('Cập nhật sản phẩm thất bại');
          },
        }
      );

      return;
    }

    createProductMutation.mutate(dataSubmit, {
      onSuccess: () => {
        toast.showMessageSuccess('Tạo sản phẩm thành công');
        history.push('/admin/product');
      },
      onError: () => {
        toast.showMessageError('Tạo sản phẩm thất bại');
      },
    });
  };

  const onRemove = index => {
    setFiles(prev => prev.filter((i, idX) => idX !== index));
  };

  const handlePublicProduct = () => {
    changeStatusProductMutation.mutate(
      { id },
      {
        onSuccess: () => {
          toast.showMessageSuccess(`Thay đổi trạng thái phát hành sản phẩm thành công`);
          refetch?.();
        },
        onError: () => {
          toast.showMessageError(`Thay đổi trạng thái phát hành sản phẩm thất bại`);
          refetch?.();
        },
      }
    );
  };

  const handleChangeStatusSoldoutProduct = () => {
    changeStatusSoldoutProductMutation.mutate(
      { id },
      {
        onSuccess: () => {
          toast.showMessageSuccess(`Thay đổi trạng thái hết sản phẩm thành công`);
          refetch?.();
        },
        onError: () => {
          toast.showMessageError(`Thay đổi trạng thái hết sản phẩm thất bại`);
          refetch?.();
        },
      }
    );
  };

  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px' }}>
      <Card overflowX={{ sm: 'scroll', xl: 'hidden' }} pb="0px" bg="white">
        <CardHeader p="6px 0px 22px 0px">
          <Flex alignItems="center" justifyContent="space-between">
            <Text fontSize="xl" fontWeight="bold">
              {`${id ? 'Cập nhật' : 'Tạo'} sản phẩm`}
            </Text>
            <Flex alignItems="center" gap={10}>
              {!!id && (
                <Flex flexDirection="column" alignItems="center" gap={1}>
                  <Text>Hết hàng</Text>
                  <Switch size="md" isChecked={productDetailData?.data?.isSoldOut} onChange={handleChangeStatusSoldoutProduct} />
                </Flex>
              )}
              {!!id && (
                <Flex flexDirection="column" alignItems="center" gap={1}>
                  <Text>Phát hành</Text>
                  <Switch size="md" isChecked={productDetailData?.data?.isPublished} onChange={handlePublicProduct} />
                </Flex>
              )}
            </Flex>
          </Flex>
        </CardHeader>
        <CardBody pb={4}>
          <form>
            <InputController control={control} name="name" label="Tên" isRequired styleContainer={{ pt: '4' }} />
            <NumericInputController control={control} name="price" label="Xu quy đổi" isRequired styleContainer={{ pt: '4' }} />
            <NumericInputController
              control={control}
              name="totalQuantity"
              label="Số lượng (Tổng số lượng ở mục thông tin thêm)"
              isRequired
              styleContainer={{ pt: '4' }}
            />
            <InputController control={control} name="tags" label="Tags" styleContainer={{ pt: '4' }} />
            <InputController type="textarea" control={control} name="description" label="Mô tả" styleContainer={{ pt: '4' }} />
            <SelectController
              styleContainer={{ pt: '4' }}
              control={control}
              isRequired
              name="category"
              label="Danh mục"
              isClearable
              options={categoryOption}
            />
            <FormControl pt={'4'}>
              <FormLabel minW="150px">Biến thể sản phẩm</FormLabel>
              <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                <GridItem w="100%">
                  <InputController control={control} name="variantLabel_1" label={`Nhãn biến thể 1`} />
                </GridItem>
                <GridItem w="100%">
                  <InputController control={control} name="variantLabel_2" label={`Nhãn biến thể 2`} />
                </GridItem>
              </Grid>
            </FormControl>

            {!isEmpty(fields) && (
              <Box borderWidth="1px" borderStyle="dashed" borderRadius="12px" padding="16px" mt={4}>
                {fields.map((field, index) => {
                  return (
                    <Flex key={index} gap={4} alignItems="center" mb={2}>
                      <Box minW="80px">
                        <Text fontWeight="600" minW="80px">{`Thuộc tính`}</Text>
                        {!!id && (
                          <>
                            <Text fontStyle="italic" minW="80px">{`SL đã đổi: ${field?.exchanged}`}</Text>
                            <Text fontStyle="italic" minW="80px">{`SL còn: ${field?.remaining}`}</Text>
                          </>
                        )}
                      </Box>
                      <InputController control={control} name={`informations.${index}.color`} label={`Thuộc tính biến thể 1`} />
                      <InputController control={control} name={`informations.${index}.size`} label={`Thuộc tính biến thể 2`} />
                      <NumericInputController control={control} name={`informations.${index}.quantity`} label="Số lượng" isRequired />
                      <IconButton
                        onClick={() => {
                          remove(index);
                        }}
                      >
                        <CloseIcon boxSize={3} />
                      </IconButton>
                    </Flex>
                  );
                })}
              </Box>
            )}
            <Flex justifyContent="flex-end" pt={'4'}>
              <Button
                onClick={() => {
                  append({ size: '', color: '', quantity: '' });
                }}
              >
                Thêm biến thể
              </Button>
            </Flex>
            <FormControl pt={'4'}>
              <FormLabel minW="150px">Chi tiết sản phẩm</FormLabel>
            </FormControl>
            {!isEmpty(fieldsMetaData) && (
              <Box borderWidth="1px" borderStyle="dashed" borderRadius="12px" padding="16px" mt={4}>
                {fieldsMetaData.map((field, index) => {
                  return (
                    <Flex key={index} gap={4} alignItems="center" mb={2}>
                      <InputController control={control} name={`metaData.${index}.key`} label="Nhãn" isRequired />
                      <InputController control={control} name={`metaData.${index}.value`} label="Giá trị" isRequired />
                      <IconButton
                        onClick={() => {
                          removeMetaData(index);
                        }}
                      >
                        <CloseIcon boxSize={3} />
                      </IconButton>
                    </Flex>
                  );
                })}
              </Box>
            )}
            <Flex justifyContent="flex-end" pt={4}>
              <Button
                onClick={() => {
                  appendMetaData({ key: '', value: '' });
                }}
              >
                Thêm chi tiết sản phẩm
              </Button>
            </Flex>
            <FormControl pt={4}>
              <FormLabel minW="150px">Ảnh</FormLabel>
              <Box
                {...getRootProps()}
                cursor="pointer"
                borderWidth="1px"
                borderStyle="dashed"
                borderRadius="12px"
                padding="55px"
                textAlign="center"
                height="210px"
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <input {...getInputProps()} />
                <BsUpload size={40} />
                {isDragEnter ? (
                  <p>Thả ảnh của bạn</p>
                ) : (
                  <>
                    <p>
                      <Text as="span" color="red.400">
                        Tải tập tin lên
                      </Text>
                      &nbsp;hoặc kéo và thả
                    </p>
                  </>
                )}
              </Box>
              <Grid templateColumns="repeat(5, 1fr)" gap={6} pt={5}>
                {files?.map((file, index) => (
                  <GridItem w={'100%'} key={file.id} position="relative">
                    <Image src={file.preview} w="full" h="full" objectFit="contain" />
                    <IconButton bg="transparent" position="absolute" top="0" right="0" onClick={() => onRemove(index)}>
                      <BsXCircle size={16} color="red" />
                    </IconButton>
                  </GridItem>
                ))}
              </Grid>
              {!!error.thumbnailFile && (
                <Text pt={1} color={'red.500'} fontSize="13px">
                  {error.thumbnailFile}
                </Text>
              )}
            </FormControl>
          </form>
          <Flex pt={6} alignItems="flex-end" justifyContent="flex-end">
            <Button
              onClick={() => {
                history.goBack();
              }}
            >
              Hủy
            </Button>
            <Button
              colorScheme="blue"
              ml={3}
              isLoading={createProductMutation.isPending || updateProductMutation.isPending}
              onClick={handleSubmit(onSubmit)}
            >
              {!!id ? 'Cập nhật ' : 'Tạo'}
            </Button>
          </Flex>
        </CardBody>
      </Card>
    </Flex>
  );
}
