import { useState } from 'react';
import { Button, Flex, Stack, Text, useColorModeValue, FormControl, FormLabel, Input } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import Pagination from 'components/Pagination/Pagination';
import ProductTable from './components/Table';
import { Select } from 'chakra-react-select';
import { MissionBoOption, ResultShipOptions, ShipStatusOptions } from 'constants/common';
import { useGetOrderShipMutation, useQueryGetListShipOrder } from 'services/shipping';
import DatePicker from 'components/DatePicker/DatePicker';
import moment from 'moment';
import { downloadFile } from 'utils/helpers';

export default function ShipOrder() {
  const history = useHistory();
  const textColor = useColorModeValue('gray.700', 'white');
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [searchTitle, setSearchTitle] = useState('');
  const [status, setStatus] = useState();
  const [type, setType] = useState();
  const [filterDate, setFilterDate] = useState({
    start: null,
    end: null,
  });

  const { data: ships, refetch } = useQueryGetListShipOrder(filter);
  const exportTemplateShipOrderMutation = useGetOrderShipMutation(
    {
      ...filter,
      status: status?.value,
      result: type?.value,
      searchKeyword: searchTitle,
      ...(filterDate?.start && { start: moment(filterDate?.start).startOf('day').toISOString() }),
      ...(filterDate?.end && { end: moment(filterDate?.end).endOf('day').toISOString() }),
      responseType: 'arraybuffer',
    },
    {
      responseType: 'arraybuffer',
    }
  );

  const handleSearch = () => {
    setFilter({
      ...filter,
      status: status?.value,
      result: type?.value,
      searchKeyword: searchTitle,
      ...(filterDate?.start && { start: moment(filterDate?.start).startOf('day').toISOString() }),
      ...(filterDate?.end && { end: moment(filterDate?.end).endOf('day').toISOString() }),
    });
  };

  const handleReset = () => {
    setSearchTitle('');
    setFilter({
      pageIndex: 0,
      pageSize: 10,
    });
    setStatus(null);
    setType(null);
    setFilterDate({
      start: null,
      end: null,
    });
  };

  const onChangeDate = type => date => {
    setFilterDate(prev => ({
      ...prev,
      ...(type === 'start' && { end: null }),
      [type]: date,
    }));
  };

  const onDownloadTemplate = () => {
    exportTemplateShipOrderMutation.mutate(undefined, {
      onSuccess: response => {
        downloadFile(response, 'ship-order-template');
        toast.showMessageSuccess('Tải mẫu đơn hàng vận chuyển thành công');
      },
      onError: () => {
        toast.showMessageError('Tải mẫu đơn hàng vận chuyển thất bại');
      },
    });
  };

  return (
    <>
      <Flex direction="column" pt={{ base: '120px', md: '75px', lg: '100px' }}>
        <Card p="16px" mb="24px" bg="#fff">
          <CardHeader p="12px 5px" mb="12px">
            <Flex justifyContent={'space-between'}>
              <Flex direction={'column'} w={'100%'}>
                <Flex justifyContent={'space-between'} gap={'30px'} w={'100%'}>
                  <Text fontSize="xl" color={textColor} fontWeight="bold">
                    Vận chuyển đơn hàng
                  </Text>
                  <Button bg="#3182ce" color="#fff" _hover={{ bg: '#67a1d7' }} isLoading={false} onClick={onDownloadTemplate}>
                    Xuất dữ liệu
                  </Button>
                </Flex>
              </Flex>
            </Flex>
            <Flex justifyContent={'space-between'} alignItems={'end'} gap={'20px'} mt={'20px'}>
              <Stack>
                <Flex alignItems={'center'} gap={'20px'} flexWrap={'wrap'}>
                  <FormControl w={'300px'}>
                    <FormLabel>Tìm kiếm vận chuyển đơn hàng</FormLabel>
                    <Input value={searchTitle} onChange={e => setSearchTitle(e.target.value)} />
                  </FormControl>
                  <FormControl w={'300px'}>
                    <FormLabel h={'21px'}>Trạng thái đơn tạo</FormLabel>
                    <Select
                      placeholder="Chọn trạng thái đơn tạo"
                      isClearable
                      menuShouldBlockScroll
                      value={status}
                      onChange={e => {
                        setStatus(e);
                      }}
                      options={ShipStatusOptions}
                    ></Select>
                  </FormControl>
                  <FormControl w={'300px'}>
                    <FormLabel h={'21px'}>Trạng thái</FormLabel>
                    <Select
                      placeholder="Chọn trạng thái"
                      isClearable
                      menuShouldBlockScroll
                      value={type}
                      onChange={e => {
                        setType(e);
                      }}
                      options={ResultShipOptions}
                    ></Select>
                  </FormControl>
                  <FormControl>
                    <Flex alignItems={'center'} flexWrap={'wrap'} gap={4}>
                      <FormControl display="flex" flexDirection="column" maxW="300px">
                        <FormLabel marginRight={0} fontSize={14} isTruncated>
                          Ngày bắt đầu
                        </FormLabel>
                        <DatePicker
                          styleInput={{
                            minWidth: '300px',
                          }}
                          selectedDate={filterDate.start}
                          onChange={date => onChangeDate('start')(date)}
                        />
                      </FormControl>
                      <FormControl display="flex" flexDirection="column" maxW="300px">
                        <FormLabel marginRight={0} fontSize={14} isTruncated>
                          Ngày kết thúc
                        </FormLabel>
                        <DatePicker
                          styleInput={{
                            minWidth: '300px',
                          }}
                          selectedDate={filterDate.end}
                          minDate={filterDate.start}
                          // maxDate={new Date(formatDate(moment(filter.start).add(6, 'days')))}
                          onChange={date => onChangeDate('end')(date)}
                        />
                      </FormControl>
                      <Button maxH="40px" alignSelf={'end'} onClick={handleReset}>
                        <Text fontSize="md" fontWeight="bold" cursor="pointer">
                          Đặt lại
                        </Text>
                      </Button>
                      <Button variant="primary" maxH="40px" alignSelf={'end'} onClick={handleSearch}>
                        <Text fontSize="md" fontWeight="bold" cursor="pointer">
                          Tìm kiếm
                        </Text>
                      </Button>
                    </Flex>
                  </FormControl>
                </Flex>
              </Stack>
            </Flex>
          </CardHeader>
          <CardBody overflowX="auto">
            <Stack overflow={'auto'}>
              <ProductTable productsData={ships?.data || []} refetch={refetch} />
            </Stack>
            <Flex justifyContent={'flex-end'}>
              {!isEmpty(ships?.data) && (
                <Pagination
                  page={ships?.pagination?.page}
                  pageLength={ships?.pagination?.pageSize}
                  totalRecords={ships?.pagination?.count}
                  onPageChange={(page, pageLength) => {
                    setFilter({
                      ...filter,
                      pageSize: pageLength,
                      pageIndex: page - 1,
                    });
                  }}
                />
              )}
            </Flex>
          </CardBody>
        </Card>
      </Flex>
      {/* {isCreateModalOpen && (
          <CreateSizeModal sizeDetail={sizeEditing} isOpen={isCreateModalOpen} onClose={handelCloseModal} refetch={refetch} />
        )} */}
    </>
  );
}
