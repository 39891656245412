import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
  Switch,
  Text,
} from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import { ModalType } from 'constants/common';

import { Box, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable, createColumnHelper } from '@tanstack/react-table';
import React, { useMemo, useState } from 'react';

export default function AddressMember({ isOpen, orderDetail, onClose }) {
  const cancelRef = React.useRef();
  const [sorting, setSorting] = useState([]);
  const columnHelper = createColumnHelper();

  const columns = useMemo(
    () => [
      columnHelper.accessor('username', {
        header: 'Tên tài khoản',
        cell: info => info.getValue(),
      }),
      columnHelper.accessor('name', {
        header: 'Tên hội viên',
        cell: info => info.getValue(),
      }),
      columnHelper.accessor('phone', {
        header: 'Số điện thoại',
        cell: info => info.getValue() || '--',
      }),
      columnHelper.accessor('isDefault', {
        header: 'Địa chỉ mặc định',
        cell: info => (
          <Switch
            size="md"
            isChecked={info.getValue()}
            readOnly
          />
        ),
      }),
      columnHelper.accessor('address', {
        header: 'Địa chỉ',
        cell: info => {
            const record = info?.row?.original;
            return <Text>{record?.address}, {record?.ward?.ward_name}, {record?.district?.district_name}, {record?.province?.province_name}</Text>
        },
      }),
    ],
    [orderDetail?.data]
  );

  const table = useReactTable({
    data: orderDetail?.data || [],
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <AlertDialog
      motionPreset="slideInBottom"
      leastDestructiveRef={cancelRef}
      onClose={() => {
        onClose(ModalType.Detail);
      }}
      isOpen={isOpen}
      isCentered
    >
      <AlertDialogOverlay />
      <AlertDialogContent maxW={'90%'}>
        <AlertDialogHeader textTransform="uppercase">Thông tin địa chỉ nhận hàng</AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>
          <Table>
            <Thead>
              {table.getHeaderGroups().map(headerGroup => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map(header => (
                    <Th key={header.id} w="120px">
                      {header.isPlaceholder ? null : (
                        <Box
                          isTruncated
                          cursor={header.column.getCanSort() ? 'pointer' : 'default'}
                          onClick={header.column.getToggleSortingHandler()}
                        >
                          {flexRender(header.column.columnDef.header, header.getContext())}
                          {{
                            asc: ' 🔼',
                            desc: ' 🔽',
                          }[header.column.getIsSorted()] ?? null}
                        </Box>
                      )}
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody>
              {isEmpty(table.getRowModel().rows) ? (
                <Tr>
                  <Td textAlign="center" colSpan={6}>
                    Không có dữ liệu
                  </Td>
                </Tr>
              ) : (
                table.getRowModel().rows.map(row => (
                  <Tr key={row.id}>
                    {row.getVisibleCells().map(cell => (
                      <Td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>
                    ))}
                  </Tr>
                ))
              )}
            </Tbody>
          </Table>
        </AlertDialogBody>
      </AlertDialogContent>
    </AlertDialog>
  );
}
