import { Box, Table, Tbody, Td, Text, Th, Thead, Tr, Flex } from '@chakra-ui/react';
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable, createColumnHelper } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { IconButton } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import { ViewIcon } from '@chakra-ui/icons';
import { formatDate } from 'utils/helpers';
import { AdjustCoinType, GAME_TYPE_MISSION, ModalType, TYPE_UPDATE_COIN } from 'constants/common';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { BiCommentDetail } from 'react-icons/bi';
import moment from 'moment';

const HistoryTable = ({ tableData, handleShowMemberDetail }) => {
  const [sorting, setSorting] = useState([]);
  const columnHelper = createColumnHelper();
  const history = useHistory();

  // const handleRowClick = (ticket, type) => {
  //   handleUpdateCategory(ticket, type);
  // };

  const handleViewClick = (idMember, ModalType) => {
    handleShowMemberDetail(idMember, ModalType);
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor('type', {
        header: 'Loại điều chỉnh',
        cell: info => TYPE_UPDATE_COIN[info.getValue()],
      }),
      columnHelper.accessor('preBalance', {
        header: 'Số lượng xu trước khi điều chỉnh',
        cell: info => info.getValue(),
      }),
      columnHelper.accessor('amount', {
        header: 'Số lượng xu điều chỉnh',
        cell: info => `${info.row.original.type === AdjustCoinType.AddCoin ? '+' : '-'} ${info.getValue()}`,
      }),
      columnHelper.accessor('currBalance', {
        header: 'Số lượng xu sau khi điều chỉnh',
        cell: info => info.getValue(),
      }),
      columnHelper.accessor('note', {
        header: 'Ghi chú',
        cell: info => info.getValue(),
      }),
      columnHelper.accessor('actionBy', {
        header: 'Người điều chỉnh',
        cell: info => info.getValue(),
      }),
      columnHelper.accessor('createdAt', {
        header: 'Ngày điều chỉnh',
        cell: info => formatDate(info.getValue(), 'DD-MM-YYYY HH:mm:ss'),
      }),
    ],
    [tableData]
  );

  const table = useReactTable({
    data: tableData || [],
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <Table>
      <Thead>
        {table.getHeaderGroups().map(headerGroup => (
          <Tr key={headerGroup.id}>
            {headerGroup.headers.map(header => (
              <Th key={header.id} w="120px">
                {header.isPlaceholder ? null : (
                  <Box
                    isTruncated
                    cursor={header.column.getCanSort() ? 'pointer' : 'default'}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {{
                      asc: ' 🔼',
                      desc: ' 🔽',
                    }[header.column.getIsSorted()] ?? null}
                  </Box>
                )}
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody>
        {isEmpty(table.getRowModel().rows) ? (
          <Tr>
            <Td textAlign="center" colSpan={6}>
              Không có dữ liệu
            </Td>
          </Tr>
        ) : (
          table.getRowModel().rows.map(row => (
            <Tr key={row.id}>
              {row.getVisibleCells().map(cell => (
                <Td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>
              ))}
            </Tr>
          ))
        )}
      </Tbody>
    </Table>
  );
};

export default HistoryTable;
