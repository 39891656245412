import { Box, Flex, FormControl, IconButton, Image, Switch, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable, createColumnHelper } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import isEmpty from 'lodash/isEmpty';
import { useHistory } from 'react-router-dom';
import { formatDate } from 'utils/helpers';
import { FORMAT_DATE, GAME_TYPE_MISSION, MISSION_TYPE } from 'constants/common';
import { toast } from 'components/Toast';
import { useChangeStatusMission, useDeleteMission } from 'services/mission';
import capitalize from 'lodash/capitalize';
import { formatImageUrl } from 'utils/url';
import MissionImgDefault from 'assets/img/mission-coin.png';
import AuthorizationComponentWrapper from 'components/AuthorizationComponentWrapper/AuthorizationComponentWrapper';
import { useUserState } from 'context/UserContext';

const getNameTypeMission = type => {
  switch (type) {
    case MISSION_TYPE.BET_IN_DAY:
      return 'Cược trong ngày';
    case MISSION_TYPE.BET_IN_WEEK:
      return 'Cược trong tuần';
    case MISSION_TYPE.RECHARGE_IN_DAY:
      return 'Nạp trong ngày';
    case MISSION_TYPE.RECHARGE_IN_WEEK:
      return 'Nạp trong tuần';
    default:
      return '';
  }
};

const MissionTable = ({ productsData, refetch }) => {
  const history = useHistory();
  const [sorting, setSorting] = useState([]);
  const columnHelper = createColumnHelper();
  const { userInfo } = useUserState();

  const changeStatusMissionMutation = useChangeStatusMission();
  const deleteMissionMutation = useDeleteMission();

  const handleSwitchChange = product => {
    changeStatusMissionMutation.mutate(
      { id: product?._id },
      {
        onSuccess: () => {
          toast.showMessageSuccess('Đã thay đổi trạng thái xuất bản sản phẩm');
          refetch?.();
        },
        onError: () => {
          toast.showMessageError('Thay đổi trạng thái xuất bản sản phẩm thất bại');
          refetch?.();
        },
      }
    );
  };

  const handleDeleteMission = async mission => {
    const confirmDelete = window.confirm('Bạn có chắc chắn muốn xóa nhiệm vụ này không?');

    if (confirmDelete) {
      deleteMissionMutation.mutate(
        { id: mission?._id },
        {
          onSuccess: () => {
            toast.showMessageSuccess('Xóa nhiệm vụ thành công');
            refetch?.();
          },
        }
      );
    }
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor('thumbnail', {
        header: 'Icon',
        cell: info => (
          <Box minW="100px" minH="100px">
            <Image src={info.renderValue() ? formatImageUrl(info.renderValue()) : MissionImgDefault} boxSize="100px" borderRadius="4px" />
          </Box>
        ),
      }),
      columnHelper.accessor('title', {
        header: 'Tiêu đề',
        cell: info => <Box textTransform="capitalize">{info.renderValue()}</Box>,
      }),
      columnHelper.accessor('level', {
        header: 'Cấp độ',
        cell: info => (
          <Box>
            {info.renderValue()?.map((item, index) => (
              <Text key={index}>{item}</Text>
            ))}
            {info.renderValue()?.length === 0 && '--'}
          </Box>
        ),
      }),
      columnHelper.accessor('type', {
        header: 'Loại',
        cell: info => getNameTypeMission(info.renderValue()),
      }),
      columnHelper.accessor('gameTypes', {
        header: 'Loại game',
        cell: info => (
          <Box>
            {info.renderValue()?.map((type, index) => (
              <Text key={index}>{GAME_TYPE_MISSION[type]}</Text>
            ))}
            {info.renderValue()?.length === 0 && '--'}
          </Box>
        ),
      }),
      columnHelper.accessor('coinCollect', {
        header: 'Số xu nhận được',
        cell: info => info.renderValue() || '--',
      }),
      columnHelper.accessor('totalAmountToCollect', {
        header: 'Tổng nạp / Tổng cược',
        cell: info => info.renderValue() || '--',
      }),
      columnHelper.accessor('isPublished', {
        header: 'Trạng thái xuất bản',
        cell: info => (
          <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'ACTIVE_MISSION'}>
            <FormControl display="flex" alignItems="center">
              <Switch isChecked={info?.row?.original?.isPublished} onChange={() => handleSwitchChange(info.row.original)} />
            </FormControl>
          </AuthorizationComponentWrapper>
        ),
      }),
      columnHelper.accessor('createdAt', {
        header: 'Ngày tạo',
        cell: info => formatDate(info.getValue(), FORMAT_DATE),
      }),
      columnHelper.accessor('action', {
        header: 'Thao tác',
        cell: info => (
          <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'UPDATE_MISSION'}>
            <Flex alignItems="center" gap={1}>
              <IconButton
                bg="transparent"
                onClick={() => {
                  history.push(`/admin/mission-create/${info?.row?.original?._id}`);
                }}
              >
                <EditIcon cursor="pointer" boxSize={4} />
              </IconButton>
              <IconButton
                bg="transparent"
                onClick={() => {
                  handleDeleteMission(info?.row?.original);
                }}
              >
                <DeleteIcon color="red.400" boxSize={4} />
              </IconButton>
            </Flex>
          </AuthorizationComponentWrapper>
        ),
      }),
    ],
    [productsData]
  );

  const table = useReactTable({
    data: productsData || [],
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <Table>
      <Thead>
        {table.getHeaderGroups().map(headerGroup => (
          <Tr key={headerGroup.id}>
            {headerGroup.headers.map(header => (
              <Th key={header.id} w="120px">
                {header.isPlaceholder ? null : (
                  <Box
                    isTruncated
                    cursor={header.column.getCanSort() ? 'pointer' : 'default'}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {{
                      asc: ' 🔼',
                      desc: ' 🔽',
                    }[header.column.getIsSorted()] ?? null}
                  </Box>
                )}
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody>
        {isEmpty(table.getRowModel().rows) ? (
          <Tr>
            <Td textAlign="center" colSpan={10}>
              Không có dữ liệu
            </Td>
          </Tr>
        ) : (
          table.getRowModel().rows.map(row => (
            <Tr key={row.id}>
              {row.getVisibleCells().map(cell => (
                <Td key={cell.id} isTruncated>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Td>
              ))}
            </Tr>
          ))
        )}
      </Tbody>
    </Table>
  );
};

export default MissionTable;
