import { useRef, useState } from 'react';
import { Button, Flex, FormControl, FormLabel, Input, Stack, Switch, Text, useColorModeValue } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import { importProduct, useExportTemplateProductMutation, useQueryGetProducts } from 'services/product';
import Pagination from 'components/Pagination/Pagination';
import ProductTable from './components/Table';
import { downloadFile } from 'utils/helpers';
import { toast } from 'components/Toast';
import { FileExcelValid } from 'constants/common';
import { Select } from 'chakra-react-select';
import { useQueryGetListCategory } from 'services/category';
import { mappingOptionSelect } from 'utils/mapping';
import AuthorizationComponentWrapper from 'components/AuthorizationComponentWrapper/AuthorizationComponentWrapper';
import { useUserState } from 'context/UserContext';

export default function Product() {
  const history = useHistory();
  const inputImportRef = useRef();
  const textColor = useColorModeValue('gray.700', 'white');
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [searchTitle, setSearchTitle] = useState('');
  const [switched, setSwitched] = useState(false);
  const [isBest, setisBest] = useState(false);
  const [mostExchanged, setmostExchanged] = useState(false);
  const [categoryId, setcategoryId] = useState(false);
  const [status, setStatus] = useState();
  const [character, setCharacter] = useState();
  const [sort, setSort] = useState();
  const { userInfo } = useUserState();

  const { data: productsData, refetch } = useQueryGetProducts(filter);
  const exportTemplateProductMutation = useExportTemplateProductMutation(
    {
      ...filter,
      responseType: 'arraybuffer',
      isExport: true,
      searchKeyword: searchTitle,
      isPublished: status?.value === 1 ? true : status?.value === 2 ? false : null,
      isBest: character?.value === 1 ? true : character?.value === 2 ? false : null,
      mostExchanged: sort?.value === 2 ? -1 : 1,
      isLatest: sort?.value === 1 ? -1 : 1,
      categoryId: categoryId?.value ? categoryId.value : null,
    },
    {
      responseType: 'arraybuffer',
    }
  );
  const { data: categoryData } = useQueryGetListCategory();

  const onDownloadTemplate = () => {
    exportTemplateProductMutation.mutate(undefined, {
      onSuccess: response => {
        downloadFile(response, 'product-template');
        toast.showMessageSuccess('Tải mẫu sản phẩm thành công');
      },
      onError: () => {
        toast.showMessageError('Tải mẫu sản phẩm thất bại');
      },
    });
  };

  const handleImportProduct = async e => {
    if (e?.target?.files?.[0]) {
      const productFile = e.target.files[0];
      const extensionFile = productFile?.name?.split('.')?.pop();

      if (FileExcelValid.includes(extensionFile)) {
        const formData = new FormData();

        formData.append('productFile', productFile);

        await importProduct(formData)
          .then(() => {
            toast.showMessageSuccess('Tải lên sản phẩm thành công');
            refetch();
          })
          .catch(() => {
            toast.showMessageError('Tải lên sản phẩm thất bại');
          });

        return;
      }

      setError('Chỉ hỗ trợ tải lại file định dạng .xlsx, xls');
    }
  };

  const onFilter = () => {
    setFilter({
      ...filter,
      searchKeyword: searchTitle,
      isPublished: status?.value === 1 ? true : status?.value === 2 ? false : null,
      isBest: character?.value === 1 ? true : character?.value === 2 ? false : null,
      mostExchanged: sort?.value === 2 ? -1 : 1,
      isLatest: sort?.value === 1 ? -1 : 1,
      categoryId: categoryId?.value ? categoryId.value : null,
    });
  };

  const onReset = () => {
    setFilter({
      pageIndex: 0,
      pageSize: 10,
    });
    setSearchTitle('');
    setSwitched(false);
    setisBest(false);
    setmostExchanged(false);
    setcategoryId(null);
    setCharacter(null);
    setStatus(null);
    setSort(null);
  };

  return (
    <>
      <Flex direction="column" pt={{ base: '120px', md: '75px', lg: '100px' }}>
        <Card p="16px" mb="24px" bg="#fff">
          <CardHeader p="12px 5px" mb="12px">
            <Flex justifyContent={'space-between'}>
              <Flex direction={'column'}>
                <Flex direction="column" gap={'30px'}>
                  <Text fontSize="xl" color={textColor} fontWeight="bold">
                    Sản phẩm
                  </Text>
                </Flex>
                <Flex justifyContent={'space-between'} alignItems={'end'} gap={'20px'} my={'20px'}>
                  <Stack>
                    <Flex gap={'20px'} flexDirection={'column'} flexWrap={{ base: 'wrap', md: 'nowrap' }}>
                      <Flex alignItems={'center'} justifyContent={'start'} gap={5} flexWrap="wrap">
                        <FormControl w="200px">
                          <FormLabel>Tìm kiếm</FormLabel>
                          <Input value={searchTitle} onChange={e => setSearchTitle(e.target.value)} />
                        </FormControl>
                        <FormControl w="200px">
                          <FormLabel>Trạng thái</FormLabel>
                          <Select
                            placeholder="Chọn trạng thái"
                            isClearable
                            value={status}
                            menuShouldBlockScroll
                            onChange={e => {
                              setStatus(e);
                            }}
                            options={[
                              {
                                label: 'Phát hành',
                                value: 1,
                              },
                              {
                                label: 'Lưu nháp',
                                value: 2,
                              },
                            ]}
                          ></Select>
                        </FormControl>
                        <FormControl w="200px">
                          <FormLabel>Đặc điểm</FormLabel>
                          <Select
                            placeholder="Chọn đặc điểm"
                            isClearable
                            value={character}
                            menuShouldBlockScroll
                            onChange={e => {
                              setCharacter(e);
                            }}
                            options={[
                              {
                                label: 'Nổi bật',
                                value: 1,
                              },
                              {
                                label: 'Bình thường',
                                value: 2,
                              },
                            ]}
                          ></Select>
                        </FormControl>
                        <FormControl w="200px">
                          <FormLabel>Danh mục</FormLabel>
                          <Select
                            placeholder="Chọn danh mục"
                            isClearable
                            value={categoryId}
                            menuShouldBlockScroll
                            onChange={e => {
                              setcategoryId(e);
                            }}
                            options={mappingOptionSelect(categoryData?.data, 'title', '_id')}
                          ></Select>
                        </FormControl>
                        <FormControl w="200px">
                          <FormLabel>Sắp xếp sản phẩm</FormLabel>
                          <Select
                            placeholder="Sắp xếp theo"
                            isClearable
                            value={sort}
                            menuShouldBlockScroll
                            onChange={e => {
                              setSort(e);
                            }}
                            options={[
                              {
                                label: 'Sản phẩm mới nhất',
                                value: 1,
                              },
                              {
                                label: 'Sản phẩm đổi nhiều nhất',
                                value: 2,
                              },
                            ]}
                          ></Select>
                        </FormControl>
                      </Flex>
                      <Flex alignItems="center" gap={10}>
                        {/* <FormControl>
                          <FormLabel>Phát hành</FormLabel>
                          <Switch
                            colorScheme="blue"
                            isChecked={switched}
                            onChange={event => {
                              setSwitched(event.target.checked);
                            }}
                          />
                        </FormControl>
                        <FormControl>
                          <FormLabel isTruncated>Sản phẩm tốt nhất</FormLabel>
                          <Switch
                            colorScheme="blue"
                            isChecked={isBest}
                            onChange={event => {
                              setisBest(event.target.checked);
                            }}
                          />
                        </FormControl>
                        <FormControl>
                          <FormLabel isTruncated>Sản phẩm đổi nhiều nhất</FormLabel>
                          <Switch
                            colorScheme="blue"
                            isChecked={mostExchanged}
                            onChange={event => {
                              setmostExchanged(event.target.checked);
                            }}
                          />
                        </FormControl> */}
                        <Flex gap={6} alignItems={'center'}>
                          <Button
                            maxH="30px"
                            style={{
                              padding: '0 20px',
                            }}
                            onClick={onReset}
                            alignSelf={'end'}
                          >
                            Cài lại
                          </Button>
                          <Button
                            variant="primary"
                            style={{
                              padding: '0 20px',
                            }}
                            maxH="30px"
                            onClick={onFilter}
                            alignSelf={'end'}
                          >
                            Lọc
                          </Button>
                        </Flex>
                      </Flex>
                    </Flex>
                  </Stack>
                </Flex>
              </Flex>
              <Flex gap={2} alignItems={'center'} alignSelf={'start'}>
                <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'EXPORT_PRODUCT'}>
                  <Button bg="#3182ce" color="#fff" _hover={{ bg: '#67a1d7' }} isLoading={false} onClick={onDownloadTemplate}>
                    Tải template
                  </Button>
                  <Flex alignItems="center">
                    <input type="file" hidden ref={inputImportRef} onChange={handleImportProduct} />
                    <Button
                      bg="#3182ce"
                      color="#fff"
                      _hover={{ bg: '#67a1d7' }}
                      onClick={() => {
                        inputImportRef?.current?.click();
                      }}
                    >
                      Import sản phẩm
                    </Button>
                  </Flex>
                </AuthorizationComponentWrapper>
                <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'UPDATE_PRODUCT'}>
                  <Button
                    bg="#3182ce"
                    color="#fff"
                    _hover={{ bg: '#67a1d7' }}
                    onClick={() => {
                      history.push('/admin/product/create');
                    }}
                  >
                    <Text fontSize="md" fontWeight="bold" cursor="pointer">
                      Thêm
                    </Text>
                  </Button>
                </AuthorizationComponentWrapper>
              </Flex>
            </Flex>
          </CardHeader>
          <CardBody overflowX="auto">
            <Stack overflow={'auto'}>
              <ProductTable productsData={productsData?.data || []} refetch={refetch} />
            </Stack>
            <Flex justifyContent={'flex-end'}>
              {!isEmpty(productsData?.data) && (
                <Pagination
                  page={productsData?.pagination?.page}
                  pageLength={productsData?.pagination?.pageSize}
                  totalRecords={productsData?.pagination?.count}
                  onPageChange={(page, pageLength) => {
                    setFilter({
                      ...filter,
                      pageSize: pageLength,
                      pageIndex: page - 1,
                    });
                  }}
                />
              )}
            </Flex>
          </CardBody>
        </Card>
      </Flex>
      {/* {isCreateModalOpen && (
          <CreateSizeModal sizeDetail={sizeEditing} isOpen={isCreateModalOpen} onClose={handelCloseModal} refetch={refetch} />
        )} */}
    </>
  );
}
