import { useMutation, useQuery } from '@tanstack/react-query';
import { request } from 'utils/request';

export const getListMission = params => request.get('/api/v1/mission', { params });
export const createMission = data => request.post('/api/v1/mission/create-mission', data);
export const updateMission = data => request.post('/api/v1/mission/update', data);
export const getMissionDetail = id => request.get(`/api/v1/mission/${id}`);
export const changeStatusMission = data => request.post('/api/v1/mission/change-status', data);
export const deleteMission = data => request.post('/api/v1/mission/delete-mission', data);
export const uploadThumbnailMission = (data, config) =>
  request.post('/api/v1/mission/upload-thumbnail', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    ...config,
  });

// Mutation
export const useCreateMission = () => useMutation({ mutationFn: createMission });
export const useUpdateMission = () => useMutation({ mutationFn: updateMission });
export const useChangeStatusMission = () => useMutation({ mutationFn: changeStatusMission });
export const useDeleteMission = () => useMutation({ mutationFn: deleteMission });

// Query
export const useQueryGetMission = (params = {}, options = {}) =>
  useQuery({ queryKey: ['GET_MISSION', params], queryFn: () => getListMission(params), ...options });
export const useQueryGetMissionDetail = (id, options = {}) =>
  useQuery({ queryKey: ['GET_MISSION_DETAIL', id], queryFn: () => getMissionDetail(id), ...options });
