import React, { useState, useEffect } from 'react';
import { Button, Flex, Text } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import isEmpty from 'lodash/isEmpty';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import SelectController from 'components/Form/SelectController';
import InputController from 'components/Form/InputController';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import { mappingOptionSelect } from 'utils/mapping';
import { UpdateAddressFormValidate } from 'utils/validation';
import { useQueryShipOrderDetail, useUpdateAddressShipOrderMutation } from 'services/shipping';
import { useGetDistrictsByProvinceMutation, useGetWardsByProvinceMutation, useQueryGetProvinces } from 'services/address';
import { toast } from 'components/Toast';
import queryString from 'query-string';

export default function ShipAddressForm() {
  const params = useParams();
  const query = useLocation();
  const { id } = params || {};
  const { shipId } = queryString.parse(query.search);
  const history = useHistory();

  const [provinceSelected, setProvinceSelected] = useState();
  const [districtSelected, setDistrictSelected] = useState();
  const [wardOptions, setWardOptions] = useState([]);
  const { data: provinces } = useQueryGetProvinces();
  const getDistrictsByProvinceMutation = useGetDistrictsByProvinceMutation();
  const getWardsByProvinceMutation = useGetWardsByProvinceMutation();
  const updateAddress = useUpdateAddressShipOrderMutation();
  const { data: shipDetailData } = useQueryShipOrderDetail(shipId, {
    enabled: !!shipId,
  });

  const { handleSubmit, control, setValue, reset } = useForm({
    resolver: yupResolver(UpdateAddressFormValidate),
  });

  const handleSelectProvince = e => {
    setValue('district', null);
    setValue('ward', null);
    getDistrictsByProvinceMutation.mutate(
      { provinceId: e.value },
      {
        onSuccess: res => {
          setDistrictSelected(mappingOptionSelect(res?.data || [], 'district_name', 'district_id'));
        },
      }
    );
  };

  const findItemSelected = (name, data, nameField) => {
    const itemFinded = data?.find(item => item[nameField] === name);
    return itemFinded;
  };

  const handleRenderInforAddressSelectedType = (keyCity, keyDistrict, keyWard, keyName, keyPhoneNumber, keyAddress) => {
    const province = findItemSelected(shipDetailData?.data[keyCity], provinces?.data, 'province_name');
    getDistrictsByProvinceMutation.mutate(
      { provinceId: province?.province_id },
      {
        onSuccess: res => {
          const districtSelected = findItemSelected(shipDetailData?.data[keyDistrict], res?.data, 'district_name');
          getWardsByProvinceMutation.mutate(
            { districtsId: districtSelected?.district_id },
            {
              onSuccess: res => {
                const wardSelected = findItemSelected(shipDetailData?.data[keyWard], res?.data, 'ward_name');
                setValue('ward', {
                  label: wardSelected?.ward_name,
                  value: wardSelected?.ward_id,
                });
                setValue('name', shipDetailData?.data[keyName]);
                setValue('phone', shipDetailData?.data[keyPhoneNumber]);
                setValue('address', shipDetailData?.data[keyAddress]);
                setValue('district', {
                  label: districtSelected?.district_name,
                  value: districtSelected?.district_id,
                });
                setValue('province', {
                  label: province?.province_name,
                  value: province?.province_id,
                });
                setValue('ward', {
                  label: wardSelected?.ward_name,
                  value: wardSelected?.ward_id,
                });
                setWardOptions(mappingOptionSelect(res?.data || [], 'ward_name', 'ward_id'));
              },
            }
          );
          setDistrictSelected(mappingOptionSelect(res?.data || [], 'district_name', 'district_id'));
        },
      }
    );
  }

  const handleSelectType = e => {
    if (e.value === 1) {
      handleRenderInforAddressSelectedType('sourceCity', 'sourceDistrict','sourceWard', 'sourceName','sourcePhoneNumber', 'sourceAddress');
    }
    if (e.value === 2) {
      handleRenderInforAddressSelectedType('destCity', 'destDistrict','destWard', 'destName','destPhoneNumber', 'destAddress');
    }
    if (e.value === 3) {
      handleRenderInforAddressSelectedType('returnCity', 'returnDistrict','returnWard', 'returnName','returnPhoneNumber', 'returnAddress');
    }
  };

  const handleSelectDistrict = e => {
    setValue('ward', null);
    getWardsByProvinceMutation.mutate(
      { districtsId: e.value },
      {
        onSuccess: res => {
          setWardOptions(mappingOptionSelect(res?.data || [], 'ward_name', 'ward_id'));
        },
      }
    );
  };

  const handleSuccess = () => {
    toast.showMessageSuccess('Cập nhật địa chỉ thành công');
    history.goBack();
  };

  const handleError = error => {
    toast.showMessageError(
      error?.response?.data?.errors?.[0]?.msg || error?.response?.data?.msg || `${addressDetail ? 'Cập nhật' : 'Tạo'} địa chỉ thành công`
    );
  };

  const onSubmit = values => {
    if (shipId) {
      const payload = {
        orderId: id,
        addressType: values.addressType?.value,
        address: values.address,
        phoneNumber1: values.phone,
        cityName: values.province.label,
        districtName: values.district.label,
        wardName: values.ward.label,
        contactName: values.name,
      };
      updateAddress.mutate(payload, {
        onSuccess: () => {
          handleSuccess();
        },
        onError: error => handleError(error),
      });
    }
  };

  useEffect(() => {
    if (!isEmpty(provinces?.data)) {
      setProvinceSelected(mappingOptionSelect(provinces?.data, 'province_name', 'province_id'));
    }
  }, [provinces?.data]);

  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px' }}>
      <Card overflowX={{ sm: 'scroll', xl: 'hidden' }} pb="0px" bg="white">
        <CardHeader p="6px 0px 22px 0px">
          <Flex alignItems="center" justifyContent="space-between">
            <Text fontSize="xl" fontWeight="bold">
              {'Cập nhập địa chỉ đơn hàng vận chuyển'}
            </Text>
          </Flex>
        </CardHeader>
        <CardBody pb={4}>
          <form>
            <SelectController
              styleBoxInput={{
                marginBottom: '10px',
              }}
              label="Loại địa chỉ"
              name="addressType"
              placeholder="Chọn địa chỉ"
              control={control}
              controlClassName={'!min-h-[39px]'}
              onChange={e => {
                handleSelectType(e);
              }}
              options={[
                {
                  value: 1,
                  label: 'Địa chỉ lấy hàng',
                },
                {
                  value: 2,
                  label: 'Địa chỉ giao',
                },
                {
                  value: 3,
                  label: 'Địa chỉ hoàn trả',
                },
              ]}
            />
            <InputController
              styleBoxInput={{
                marginBottom: '10px',
              }}
              label="Tên liên lạc"
              name="name"
              id={'usernameInput'}
              control={control}
              type="text"
              placeholder={'Họ tên người nhận'}
            />
            <InputController
              styleBoxInput={{
                marginBottom: '10px',
              }}
              label="Số điện thoại"
              name="phone"
              id={'passwordInput'}
              control={control}
              type={'number'}
              placeholder={'Trùng với SĐT đăng ký tài khoản'}
            />
            <InputController
              styleBoxInput={{
                marginBottom: '10px',
              }}
              label="Địa chỉ"
              name="address"
              id={'passwordInput'}
              control={control}
              type={'text'}
              placeholder={'Số nhà và tên đường'}
            />
            <SelectController
              styleBoxInput={{
                marginBottom: '10px',
              }}
              label="Tỉnh/Thành phố"
              name="province"
              placeholder="Chọn Tỉnh/Thành phố"
              control={control}
              controlClassName={'!min-h-[39px]'}
              onChange={e => {
                handleSelectProvince(e);
              }}
              options={provinceSelected}
            />
            <SelectController
              styleBoxInput={{
                marginBottom: '10px',
              }}
              label="Quận/Huyện"
              name="district"
              placeholder="Chọn Quận/Huyện"
              control={control}
              controlClassName={'!min-h-[39px]'}
              onChange={e => {
                handleSelectDistrict(e);
              }}
              options={districtSelected}
            />
            <SelectController
              styleBoxInput={{
                marginBottom: '10px',
              }}
              label="Phường"
              name="ward"
              placeholder="Chọn Phường"
              control={control}
              controlClassName={'!min-h-[39px]'}
              options={wardOptions}
            />
          </form>
          <Flex pt={6} alignItems="flex-end" justifyContent="flex-end">
            <Button
              onClick={() => {
                history.goBack();
              }}
            >
              Hủy
            </Button>
            <Button
              colorScheme="blue"
              ml={3}
              // isLoading={createProductMutation.isPending || updateProductMutation.isPending}
              onClick={handleSubmit(onSubmit)}
            >
              Cập nhật
            </Button>
          </Flex>
        </CardBody>
      </Card>
    </Flex>
  );
}
