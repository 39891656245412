import React, { useEffect, useState } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import isEmpty from 'lodash/isEmpty';
import InputController from 'components/Form/InputController';
import { AddressTypeOption, ModalType } from 'constants/common';
import { toast } from 'components/Toast';
import { AddressFormValidate } from 'utils/validation';
import SelectController from 'components/Form/SelectController';
import {
  useAddAddressMutation,
  useGetDistrictsByProvinceMutation,
  useGetWardsByProvinceMutation,
  useQueryGetProvinces,
} from 'services/address';
import { mappingOptionSelect } from 'utils/mapping';

const AddAddressModal = ({ isOpen, addressDetail, onClose, refetchSourceAddress, refetchReturnAddress }) => {
  const params = useParams();
  const { id: categoryId } = params || {};
  const cancelRef = React.useRef();

  const [provinceOptions, setProvinceOptions] = useState([]);
  const [districtOptions, setDistrictsOptions] = useState([]);
  const [wardOptions, setWardOptions] = useState([]);

  const { data: provincesData } = useQueryGetProvinces();
  const getDistrictsByProvinceMutation = useGetDistrictsByProvinceMutation();
  const getWardsByProvinceMutation = useGetWardsByProvinceMutation();
  const addAddressMutation = useAddAddressMutation();

  useEffect(() => {
    if (!isEmpty(provincesData?.data)) {
      setProvinceOptions(mappingOptionSelect(provincesData?.data, 'province_name', 'province_id'));
    }
  }, [provincesData?.data]);

  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(AddressFormValidate),
    defaultValues: { type: undefined, name: '', address: '', phone: '', province: undefined, district: undefined, ward: undefined },
  });

  useEffect(() => {
    if (addressDetail) {
      reset({
        type: AddressTypeOption.find(item => item.value === addressDetail?.type),
        name: addressDetail.name,
        phone: addressDetail.phone,
        address: addressDetail.address,
        id: addressDetail?._id,
        province: provinceOptions?.find(item => item?.value === addressDetail?.province_id),
        district: districtOptions?.find(item => item?.value === addressDetail?.district_id),
        ward: wardOptions?.find(item => item?.value === addressDetail?.ward_id),
      });
    }
  }, [addressDetail, provinceOptions, districtOptions, wardOptions]);

  useEffect(() => {
    if (addressDetail?.province_id) {
      {
        handleSelectProvince({ value: addressDetail?.province_id });
      }
    }
  }, [addressDetail?.district_id]);

  useEffect(() => {
    if (addressDetail?.district_id) {
      {
        handleSelectDistrict({ value: addressDetail?.district_id });
      }
    }
  }, [addressDetail?.district_id]);

  const handleSuccess = () => {
    toast.showMessageSuccess(`${addressDetail ? 'Cập nhật' : 'Tạo'} địa chỉ thành công`);
    refetchSourceAddress?.();
    refetchReturnAddress?.();
    onClose(ModalType.Add);
  };

  const handleError = error => {
    toast.showMessageError(
      error?.response?.data?.errors?.[0]?.msg || error?.response?.data?.msg || `${addressDetail ? 'Cập nhật' : 'Tạo'} địa chỉ thành công`
    );
  };

  const handleSelectProvince = e => {
    getDistrictsByProvinceMutation.mutate(
      { provinceId: e.value },
      {
        onSuccess: res => {
          setDistrictsOptions(mappingOptionSelect(res?.data || [], 'district_name', 'district_id'));
        },
      }
    );
  };

  const handleSelectDistrict = e => {
    getWardsByProvinceMutation.mutate(
      { districtsId: e.value },
      {
        onSuccess: res => {
          setWardOptions(mappingOptionSelect(res?.data || [], 'ward_name', 'ward_id'));
        },
      }
    );
  };

  const onSubmit = values => {
    addAddressMutation.mutate(
      {
        ...values,
        province_id: values?.province?.value,
        district_id: values?.district?.value,
        ward_id: values?.ward?.value,
        type: values?.type?.value,
      },
      {
        onSuccess: () => {
          handleSuccess();
        },
        onError: error => handleError(error),
      }
    );
  };

  return (
    <>
      <AlertDialog
        size="xl"
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onClose(ModalType.Add);
        }}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader textTransform="uppercase">{addressDetail ? 'Cập nhật' : 'Tạo'} Đia chỉ</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <SelectController label="Loại" name="type" placeholder="Loai" control={control} options={AddressTypeOption} isRequired />
            <InputController control={control} name="name" label="Tên" isRequired styleContainer={{ pt: '4' }} />
            <InputController
              label="Số điện thoại"
              name="phone"
              control={control}
              placeholder="Số điện thoại"
              styleContainer={{ pt: '4' }}
            />
            <InputController control={control} name="address" label="Địa chỉ" isRequired styleContainer={{ pt: '4' }} />
            <SelectController
              label="Tỉnh/Thành phố"
              name="province"
              placeholder="Chọn Tỉnh/Thành phố"
              control={control}
              options={provinceOptions}
              isRequired
              styleContainer={{ pt: '4' }}
              onChange={e => {
                handleSelectProvince(e);
              }}
            />
            <SelectController
              label="Quận/Huyện"
              name="district"
              placeholder="Chọn Quận/Huyện"
              control={control}
              options={districtOptions}
              isRequired
              styleContainer={{ pt: '4' }}
              onChange={e => {
                handleSelectDistrict(e);
              }}
            />
            <SelectController
              label="Phường"
              name="ward"
              placeholder="Chọn Phường"
              control={control}
              options={wardOptions}
              isRequired
              styleContainer={{ pt: '4' }}
            />
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={() => {
                onClose(ModalType.Add);
              }}
            >
              Hủy
            </Button>
            <Button colorScheme="blue" ml={3} isLoading={addAddressMutation.isPending} onClick={handleSubmit(onSubmit)}>
              {addressDetail ? 'Cập nhập' : 'Tạo'}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default AddAddressModal;
