import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { CookieStorage } from 'utils/cookie-storage';
import { useHistory } from 'react-router-dom';
import { useMemo } from 'react';
import { ModalType } from 'constants/common';
import AddAddressModal from '../Address/components/CreateCategoryModal';
import { useQueryGetReturnAddress, useQueryGetSourceAddress } from 'services/address';
import AddressCard from './components/AddressCard';
import AuthorizationComponentWrapper from 'components/AuthorizationComponentWrapper/AuthorizationComponentWrapper';
import { useUserState } from 'context/UserContext';

function Address() {
  const textColor = useColorModeValue('gray.700', 'white');
  const history = useHistory();
  const [addressEditing, setAddressEditing] = useState(null);
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const { userInfo } = useUserState();

  useEffect(() => {
    if (!CookieStorage.isAuthenticated()) {
      return history.push('/auth/sign-in');
    }
  }, []);

  const { isOpen: isCreateModalOpen, onOpen: onOpenCreateModal, onClose: onCloseCreateModal } = useDisclosure();
  const { isOpen: isChangeStatusModalOpen, onOpen: onOpenChangeStatusModal, onClose: onCloseChangeStatusModal } = useDisclosure();
  const openModal = useMemo(
    () => ({
      [ModalType.Add]: onOpenCreateModal,
      [ModalType.ChangeStatus]: onOpenChangeStatusModal,
    }),
    [onOpenCreateModal, onOpenChangeStatusModal]
  );
  const closeModal = useMemo(
    () => ({
      [ModalType.Add]: onCloseCreateModal,
      [ModalType.ChangeStatus]: onCloseChangeStatusModal,
    }),
    [onCloseCreateModal, onCloseChangeStatusModal]
  );

  const { data: sourceAddressData, refetch: refetchSourceAddress } = useQueryGetSourceAddress({ enabled: CookieStorage.isAuthenticated() });
  const { data: returnAddressData, refetch: refetchReturnAddress } = useQueryGetReturnAddress({ enabled: CookieStorage.isAuthenticated() });

  const handleUpdateItem = (address, modalType) => {
    openModal?.[modalType]?.();
    setAddressEditing(address);
  };

  const handelCloseModal = modalType => {
    closeModal?.[modalType]?.();
    setAddressEditing(null);
  };

  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px', lg: '100px' }}>
      <Card p="16px" mb="24px" bg="#fff">
        <CardHeader p="12px 5px">
          <Flex justifyContent={'space-between'}>
            <Flex direction={'column'}>
              <Flex direction="column" gap={'30px'}>
                <Text fontSize="xl" color={textColor} fontWeight="bold">
                  Địa chỉ gửi và hoàn trả
                </Text>
              </Flex>
            </Flex>
            <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'UPDATE_ADDRESS'}>
              <Flex>
                <Button bg="#3182ce" color="#fff" _hover={{ bg: '#67a1d7' }} onClick={onOpenCreateModal}>
                  <Text fontSize="md" fontWeight="bold" cursor="pointer">
                    Thêm
                  </Text>
                </Button>
              </Flex>
            </AuthorizationComponentWrapper>
          </Flex>
        </CardHeader>
        <CardBody overflowX="auto">
          <AddressCard title="Địa chỉ gửi" addressDetail={sourceAddressData?.data} handleUpdateItem={handleUpdateItem} />
          <AddressCard
            title="Địa chỉ hoàn trả"
            addressDetail={returnAddressData?.data}
            styleContainer={{ mt: 4 }}
            handleUpdateItem={handleUpdateItem}
          />
        </CardBody>
      </Card>
      {isCreateModalOpen && (
        <AddAddressModal
          addressDetail={addressEditing}
          isOpen={isCreateModalOpen}
          onClose={handelCloseModal}
          refetchSourceAddress={refetchSourceAddress}
          refetchReturnAddress={refetchReturnAddress}
        />
      )}
    </Flex>
  );
}

export default Address;
