import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { CookieStorage } from 'utils/cookie-storage';
import { useHistory } from 'react-router-dom';
import { useMemo } from 'react';
import { BrandFilter, LevelMemberKkOptionFilter, LevelMemberOptionFilter, ModalType, Roles } from 'constants/common';
import SizeTable from './components/Table';
// import CreateCategoryModal from './components/CreateCategoryModal';
import Pagination from 'components/Pagination/Pagination';
import { useListMemberMutation, useQueryGetListMember } from 'services/purchase-history';
import { Select } from 'chakra-react-select';
import AddressMember from './components/AddressMember';
import { useQueryGetAddressMember } from 'services/address';
import ListOrderMember from './components/ListOrderMember';
import { useQueryGetListOrder } from 'services/shipping';
import AdjustCoinModal from './components/AdjustCoinModal';
import { downloadFile } from 'utils/helpers';
import { toast } from 'components/Toast';
import AuthorizationComponentWrapper from 'components/AuthorizationComponentWrapper/AuthorizationComponentWrapper';
import { useUserState } from 'context/UserContext';
import { useQueryBookMarker } from 'services/user';

function HistoryMember() {
  const isLoggedIn = CookieStorage.isAuthenticated();
  const textColor = useColorModeValue('gray.700', 'white');
  const history = useHistory();
  const [sizeEditing, setSizeEditing] = useState(null);
  const [idMember, setIdMember] = useState();
  const [searchTitle, setSearchTitle] = useState('');
  const [kkLevel, setKkLevel] = useState();
  const [brand, setBrand] = useState();
  const [coin, setCoin] = useState();
  const [byDate, setByDate] = useState();
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 20,
  });
  const { userInfo } = useUserState();

  useEffect(() => {
    if (!isLoggedIn) {
      return history.push('/auth/sign-in');
    }
  }, [isLoggedIn, history]);

  const { isOpen: isShowHistoryModal, onOpen: onOpenShowHistoryModal, onClose: onCloseShowHistoryModal } = useDisclosure();
  const {
    isOpen: isDetailAddressOrderMemberModal,
    onOpen: onOpenDetailAddressOrderMemberModal,
    onClose: onCloseDetailAddressOrderMemberModal,
  } = useDisclosure();
  const { isOpen: isOpenAdjustCoinModal, onOpen: onOpenAdjustCoinModal, onClose: onCloseAdjustCoinModal } = useDisclosure();

  const openModal = useMemo(
    () => ({
      [ModalType.Detail]: onOpenDetailAddressOrderMemberModal,
      [ModalType.Block]: onOpenShowHistoryModal,
      [ModalType.Adjust]: onOpenAdjustCoinModal,
    }),
    [onOpenDetailAddressOrderMemberModal, onOpenShowHistoryModal, onOpenAdjustCoinModal]
  );
  const closeModal = useMemo(
    () => ({
      [ModalType.Detail]: onCloseDetailAddressOrderMemberModal,
      [ModalType.Block]: onCloseShowHistoryModal,
      [ModalType.Adjust]: onCloseAdjustCoinModal,
    }),
    [onCloseDetailAddressOrderMemberModal, onCloseShowHistoryModal, onCloseAdjustCoinModal]
  );

  const { data: members, refetch } = useQueryGetListMember({ ...filter, isHistory: true, searchKeyword: filter.searchKeyword }, { enabled: isLoggedIn });
  const { data: addressMember } = useQueryGetAddressMember(idMember, { enabled: isLoggedIn && !!idMember });
  const { data: listOrderMember } = useQueryGetListOrder(
    {
      memberId: idMember,
    },
    { enabled: isLoggedIn && !!idMember }
  );
  const exportTemplateMemberMutation = useListMemberMutation(
    {
      ...filter,
      isExport: true,
      isHistory: true,
      searchKeyword: searchTitle,
      kkLevel: kkLevel?.value,
      brand: brand?.value,
      responseType: 'arraybuffer',
    },
    {
      responseType: 'arraybuffer',
    }
  );
  const { data: bookmarker } = useQueryBookMarker({}, { enabled: isLoggedIn });

  const getDetailMemberById = useMemo(() => {
    return members?.data.find(member => member._id === idMember);
  }, [idMember]);

  const handleUpdateItem = (size, modalType) => {
    openModal?.[modalType]?.();
    setSizeEditing(size);
  };

  const handleCloseModal = modalType => {
    closeModal?.[modalType]?.();
    setSizeEditing(null);
    setIdMember(null);
  };

  const handleSearch = () => {
    setFilter({
      ...filter,
      searchKeyword: searchTitle,
      kkLevel: kkLevel?.value,
      brand: brand?.value,
      createdAt: byDate?.value,
      coin: coin?.value,
    });
  };

  const handleReset = () => {
    setFilter({
      pageIndex: 0,
      pageSize: 10,
    });
    setKkLevel(null);
    setBrand(null);
    setSearchTitle('');
    setByDate(null);
    setCoin(null);
  };

  const handleShowMemberDetail = (idMember, modalType) => {
    openModal?.[modalType]?.();
    setIdMember(idMember);
  };

  const onDownloadTemplate = () => {
    exportTemplateMemberMutation.mutate(undefined, {
      onSuccess: response => {
        downloadFile(response, 'member-template');
        toast.showMessageSuccess('Tải mẫu hội viên thành công');
      },
      onError: () => {
        toast.showMessageError('Tải mẫu hội viên thất bại');
      },
    });
  };
  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px', lg: '100px' }}>
      <Card p="16px" mb="24px" bg="#fff">
        <CardHeader p="12px 5px" mb="12px">
          <Flex justifyContent={'space-between'}>
            <Flex direction={'column'} w={'full'}>
              <Flex justifyContent={'space-between'} gap={'30px'}>
                <Text fontSize="xl" color={textColor} fontWeight="bold">
                  Lịch sử giao dịch xu của hội viên
                </Text>
                <Button bg="#3182ce" color="#fff" _hover={{ bg: '#67a1d7' }} isLoading={false} onClick={onDownloadTemplate}>
                  Xuất dữ liệu
                </Button>
              </Flex>
              <Flex justifyContent={'space-between'} alignItems={'start'} gap={'20px'} mt={'20px'}>
                <Stack>
                  <Flex alignItems={'center'} gap={'20px'} flexWrap="wrap">
                    <FormControl w="300px">
                      <FormLabel>Tìm kiếm Thành Viên</FormLabel>
                      <Input value={searchTitle} onChange={e => setSearchTitle(e.target.value)} />
                    </FormControl>
                    <FormControl w="300px">
                      <FormLabel h={'21px'}>Cấp độ HTTĐ</FormLabel>
                      <Select
                        placeholder="Chọn cấp độ"
                        isClearable
                        menuShouldBlockScroll
                        value={kkLevel}
                        onChange={e => {
                          setKkLevel(e);
                        }}
                        options={LevelMemberKkOptionFilter}
                      ></Select>
                    </FormControl>
                    <FormControl w="300px">
                      <FormLabel h={'21px'}>Nhà cái</FormLabel>
                      <Select
                        placeholder="Chọn nhà cái"
                        isClearable
                        menuShouldBlockScroll
                        value={brand}
                        onChange={e => {
                          setBrand(e);
                        }}
                        options={BrandFilter}
                      ></Select>
                    </FormControl>
                    <FormControl w="300px">
                      <FormLabel h={'21px'}>Sắp xếp theo số coin</FormLabel>
                      <Select
                        placeholder="Chọn"
                        isClearable
                        menuShouldBlockScroll
                        value={coin}
                        onChange={e => {
                          setCoin(e);
                        }}
                        options={[
                          {
                            label: 'Ít nhất',
                            value: 1,
                          },
                          {
                            label: 'Nhiều nhất',
                            value: -1,
                          }
                        ]}
                      ></Select>
                    </FormControl>
                    <FormControl w="300px">
                      <FormLabel h={'21px'}>Sắp xếp theo thời gian</FormLabel>
                      <Select
                        placeholder="Chọn"
                        isClearable
                        menuShouldBlockScroll
                        value={byDate}
                        onChange={e => {
                          setByDate(e);
                        }}
                        options={[
                          {
                            label: 'Mới nhất',
                            value: 1,
                          },
                          {
                            label: 'Cũ nhất',
                            value: -1,
                          }
                        ]}
                      ></Select>
                    </FormControl>
                    <Button px={10} maxH="40px" alignSelf={'end'} onClick={handleReset}>
                      <Text fontSize="md" fontWeight="bold" cursor="pointer">
                        Cài lại
                      </Text>
                    </Button>
                    <Button variant="primary" px={10} maxH="40px" alignSelf={'end'} onClick={handleSearch}>
                      <Text fontSize="md" fontWeight="bold" cursor="pointer">
                        Tìm kiếm
                      </Text>
                    </Button>
                  </Flex>
                </Stack>
                <SimpleGrid spacing={5} templateColumns="repeat(auto-fill, minmax(260px, 1fr))">
                  {userInfo?.role === Roles.ADMIN &&
                    bookmarker?.data?.map(item => (
                      <Card bg="blue.100" variant={'elevated'} p={2} key={item._id}>
                        <CardHeader p={0}>
                          <Heading size="md">Hệ thống {item?.bookMarker}</Heading>
                        </CardHeader>
                        <CardBody p={0}>
                          <Text>Tổng xu đang phát hành: {item?.coin} xu</Text>
                        </CardBody>
                      </Card>
                    ))}
                  {userInfo?.role === Roles.MANAGER && bookmarker?.data?.find(item => item?.bookMarker === userInfo?.brand) && (
                    <Card bg="blue.100" variant={'elevated'} p={2}>
                      <CardHeader p={0}>
                        <Heading size="md">
                          Hệ thống {bookmarker?.data?.find(item => item?.bookMarker === userInfo?.brand)?.bookMarker}
                        </Heading>
                      </CardHeader>
                      <CardBody p={0}>
                        <Text>Tổng xu đang phát hành: {bookmarker?.data?.find(item => item?.bookMarker === userInfo?.brand)?.coin} xu</Text>
                      </CardBody>
                    </Card>
                  )}
                </SimpleGrid>
              </Flex>
            </Flex>
          </Flex>
        </CardHeader>
        <CardBody overflowX="auto">
          <Stack overflow={'auto'}>
            <SizeTable
              categorysData={members?.data || []}
              handleUpdateCategory={handleUpdateItem}
              refetch={refetch}
              handleShowMemberDetail={handleShowMemberDetail}
            />
          </Stack>
          <Flex justifyContent={'flex-end'}>
            <Pagination
              page={members?.pagination?.page}
              pageLength={members?.pagination?.pageSize}
              totalRecords={members?.pagination?.count}
              onPageChange={(page, pageLength) => {
                setFilter({
                  ...filter,
                  pageSize: pageLength,
                  pageIndex: page - 1,
                });
              }}
            />
          </Flex>
        </CardBody>
      </Card>
      {isDetailAddressOrderMemberModal && (
        <AddressMember isOpen={isDetailAddressOrderMemberModal} orderDetail={addressMember} onClose={handleCloseModal} refetch={refetch} />
      )}
      {isShowHistoryModal && (
        <ListOrderMember isOpen={isShowHistoryModal} orderDetail={listOrderMember} onClose={handleCloseModal} refetch={refetch} />
      )}
      {isOpenAdjustCoinModal && (
        <AdjustCoinModal isOpen={isOpenAdjustCoinModal} memberDetail={getDetailMemberById} onClose={handleCloseModal} refetch={refetch} />
      )}
    </Flex>
  );
}

export default HistoryMember;
