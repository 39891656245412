import React from 'react';
import { Box, Button, Flex, List, ListItem, Text } from '@chakra-ui/react';
import { useHistory, useParams } from 'react-router-dom';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import { useQueryShipOrderDetail } from 'services/shipping';
import capitalize from 'lodash/capitalize';
import { OrderStatusOption, SHIP_STATUS } from 'constants/common';
import moment from 'moment';
import { formatDate } from 'utils/helpers';

export default function ShipOrderForm() {
  const params = useParams();
  const { id } = params || {};
  const history = useHistory();

  const { data: shipDetailData } = useQueryShipOrderDetail(id, {
    enabled: !!id,
  });

  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px' }}>
      <Card overflowX={{ sm: 'scroll', xl: 'hidden' }} pb="0px" bg="white">
        <CardHeader p="6px 0px 22px 0px">
          <Flex alignItems="center" justifyContent="space-between">
            <Text fontSize="xl" fontWeight="bold">
              Chi tiết đơn hàng vận chuyển
            </Text>
          </Flex>
        </CardHeader>
        <CardBody pb={4}>
          <List spacing={3}>
            <ListItem flex>
              <Flex gap={10}>
                <Text fontWeight={600}>Mã đơn vận chuyển</Text>
                <Text>{shipDetailData?.data?.responseCode}</Text>
              </Flex>
            </ListItem>
            <ListItem>
              <Flex gap={10}>
                <Text fontWeight={600}>Thông tin đơn hàng</Text>
                <Flex gap={3} flexDir={'column'}>
                  <Flex>
                    <Text as="u" minW={'150px'}>
                      Mã đơn:
                    </Text>
                    <Text> {shipDetailData?.data?.orderId?.orderNo}</Text>
                  </Flex>
                  <Flex gap={1}>
                    <Text as="u" minW={'150px'}>
                      Sản phẩm:{' '}
                    </Text>
                    <Flex flexDir={'column'}>
                      {shipDetailData?.data?.orderId?.products?.map(product => (
                        <Text>
                          {capitalize(product?.name)} - {capitalize(product?.color)} - {capitalize(product?.size)} - x
                          {capitalize(product?.quantity)} - Xu quy đổi: {capitalize(product?.price)} - Tổng xu quy đổi:{' '}
                          {capitalize(product?.totalPrice)}
                        </Text>
                      ))}
                    </Flex>
                  </Flex>
                  <Flex>
                    <Text as="u" minW={'150px'}>
                      Trạng thái đơn hàng:
                    </Text>
                    <Text>{SHIP_STATUS?.[shipDetailData?.data?.status] || 'Đang xử lý'}</Text>
                  </Flex>
                  <Flex>
                    <Text as="u" minW={'150px'}>
                      Tổng xu quy đổi:{' '}
                    </Text>
                    <Text>{shipDetailData?.data?.orderId?.totalPrice}</Text>
                  </Flex>
                </Flex>
              </Flex>
            </ListItem>
            <Flex gap={2}>
              <Text fontWeight={600} minW={'150px'}>
                Tên sản phẩm:
              </Text>
              <Text>{shipDetailData?.data?.productName}</Text>
            </Flex>
            <Flex gap={2}>
              <Text fontWeight={600} minW={'150px'}>
                Giá sản phẩm:{' '}
              </Text>
              <Text>{shipDetailData?.data?.productPrice}</Text>
            </Flex>
            <Flex gap={2}>
              <Text fontWeight={600} minW={'150px'}>
                Ưu đãi:
              </Text>
              <Text>{shipDetailData?.data?.discount}</Text>
            </Flex>
            <Flex gap={2}>
              <Text fontWeight={600} minW={'150px'}>
                Phí vận chuyển:
              </Text>
              <Text>{shipDetailData?.data?.freight_fee}</Text>
            </Flex>
            <Flex gap={2}>
              <Text fontWeight={600} minW={'150px'}>
                Phí bảo hiểm:
              </Text>
              <Text>{shipDetailData?.data?.insurance_fee}</Text>
            </Flex>
            <Flex gap={2}>
              <Text fontWeight={600} minW={'150px'}>
                Sửa đổi:
              </Text>
              <Text>{formatDate(shipDetailData?.data?.modified_at, 'DD MMM YYYY hh:mm a')}</Text>
            </Flex>
            <Flex gap={2}>
              <Text fontWeight={600} minW={'150px'}>
                Phí hoàn trả:
              </Text>
              <Text>{shipDetailData?.data?.return_fee}</Text>
            </Flex>
            <Flex gap={2}>
              <Text fontWeight={600} minW={'150px'}>
                Tổng phí:
              </Text>
              <Text>{shipDetailData?.data?.total_fee}</Text>
            </Flex>
            <Flex gap={2}>
              <Text fontWeight={600} minW={'150px'}>
                Phí VAT:
              </Text>
              <Text>{shipDetailData?.data?.vat_fee}</Text>
            </Flex>
            <Flex flex gap={4}>
              <Flex gap={10} mb={2}>
                <Text fontWeight={600} minW={'150px'}>
                  Địa chỉ
                </Text>
              </Flex>
              <Box>
                <Flex gap={10} mb={2}>
                  <Text fontWeight={500}>Địa chỉ nhận hàng của người đặt:</Text>
                  <Text>
                    {shipDetailData?.data?.destName} - {shipDetailData?.data?.destPhoneNumber} - {shipDetailData?.data?.destAddress},
                    {shipDetailData?.data?.destWard},{shipDetailData?.data?.destDistrict},{shipDetailData?.data?.destCity}
                  </Text>
                </Flex>
                <Flex gap={10} mb={2}>
                  <Text fontWeight={500}>Địa chỉ gửi:</Text>
                  <Text>
                    {shipDetailData?.data?.sourceName} - {shipDetailData?.data?.sourcePhoneNumber} - {shipDetailData?.data?.sourceAddress},
                    {shipDetailData?.data?.sourceWard},{shipDetailData?.data?.sourceDistrict},{shipDetailData?.data?.sourceCity}
                  </Text>
                </Flex>
                <Flex gap={10} mb={2}>
                  <Text fontWeight={500}>Địa chỉ hoàn trả:</Text>
                  <Text>
                    {shipDetailData?.data?.returnName} - {shipDetailData?.data?.returnPhoneNumber} - {shipDetailData?.data?.returnAddress},
                    {shipDetailData?.data?.returnWard},{shipDetailData?.data?.returnDistrict},{shipDetailData?.data?.returnCity}
                  </Text>
                </Flex>
              </Box>
            </Flex>
            <Flex gap={10} mb={2}>
              <Text fontWeight={600} minW={'130px'}>
                Trạng thái:
              </Text>
              <Text>{shipDetailData?.data?.result === 1 ? 'Đã tạo đơn' : shipDetailData?.data?.result === 2 ? 'Đã hủy' : ''}</Text>
            </Flex>
            <Flex gap={10} mb={2}>
              <Text fontWeight={600} minW={'130px'}>
                Ghi chú:
              </Text>
              <Text>{shipDetailData?.data?.note}</Text>
            </Flex>
          </List>
          <Flex pt={6} alignItems="flex-end" justifyContent="flex-end">
            <Button
              onClick={() => {
                history.goBack();
              }}
            >
              Đóng
            </Button>
          </Flex>
        </CardBody>
      </Card>
    </Flex>
  );
}
