import { useQuery, useMutation } from '@tanstack/react-query';
import { request } from 'utils/request';

export const getSystemContent = params => request.get('/api/v1/posts', params);
export const createSystemContent = data => request.post('/api/v1/posts/create', data);
export const updateSystemContent = data => request.post('/api/v1/posts/update', data);
export const deleteSystemContent = data => request.post('/api/v1/posts/delete', data);
export const changeStatusContent = data => request.post('/api/v1/posts/change-show', data);
export const getContentDetail = id => request.get(`/api/v1/posts/${id}`);
export const uploadThumbnailSystem = (data, config) =>
  request.post('/api/v1/posts/upload-image', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    ...config,
  });

//Mutation
export const useCreateSystemContent = () => useMutation({ mutationFn: createSystemContent });
export const useUpdateSystemContent = () => useMutation({ mutationFn: updateSystemContent });
export const useDeleteSystemContent = () => useMutation({ mutationFn: deleteSystemContent });
export const useChangeStatusContentMutation = () => useMutation({ mutationFn: changeStatusContent });

//Query
export const useQueryGetSystemContent = params =>
  useQuery({ queryKey: ['GET_SYSTEM_CONTENT', params], queryFn: () => getSystemContent(params) });
export const useQueryGetContentDetail = (id, options = {}) =>
  useQuery({ queryKey: ['GET_CONTENT_DETAIL', id], queryFn: () => getContentDetail(id), ...options });
