import React, { useEffect, useState } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
} from '@chakra-ui/react';
import { Input } from '@chakra-ui/react';
import { FORMAT_DATE, ModalType } from 'constants/common';
import { formatDate } from 'utils/helpers';

const ModalShowDetailMember = ({ isOpen, onClose, memberDetail }) => {
  const cancelRef = React.useRef();
  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onClose(ModalType.Preview);
        }}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent maxW={'600px'} maxH={'400pxs'}>
          <AlertDialogHeader textTransform="uppercase">Chi tiết hội viên</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <label>Tên người dùng</label>
            <Input mb={2} value={memberDetail?.username} readOnly />
            <label>Số điện thoại</label>
            <Input mb={2} value={memberDetail?.phone} readOnly />
            <label>Cấp độ (Nhà cái)</label>
            <Input mb={2} value={memberDetail?.level || '--'} readOnly />
            <label>Cấp độ KKshop</label>
            <Input mb={2} value={memberDetail?.kkLevel || '--'} readOnly />
            <label>Nhà cái</label>
            <Input mb={2} value={memberDetail?.brand || '--'} readOnly />
            <label>Xu</label>
            <Input mb={2} value={memberDetail?.coin} readOnly />
            {/* <label>DTC hôm qua</label>
            <Input mb={2} value={memberDetail?.totalValidBetAmount_yesterday} readOnly />
            <label>Tổng nạp hôm qua</label>
            <Input mb={2} value={memberDetail?.totalRechargeAmount_yesterday} readOnly />
            <label>DTC hôm nay</label>
            <Input mb={2} value={memberDetail?.totalValidBetAmount} readOnly /> */}
            <label>Tổng nạp hôm nay</label>
            <Input mb={2} value={memberDetail?.totalRechargeAmount} readOnly />
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={() => {
                onClose(ModalType.Preview);
              }}
            >
              Đóng
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default ModalShowDetailMember;
