import { useRef, useState } from 'react';
import { Button, Flex, FormControl, FormLabel, Input, Stack, Text, useColorModeValue } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import Pagination from 'components/Pagination/Pagination';
import OrderTable from './components/Table';
import { useGetOrdersMutation, useQueryGetListOrder } from 'services/shipping';
import { Select } from 'chakra-react-select';
import { OrderStatusOption } from 'constants/common';
import { useQueryGetReturnAddress, useQueryGetSourceAddress } from 'services/address';
import { CookieStorage } from 'utils/cookie-storage';
import DatePicker from 'components/DatePicker/DatePicker';
import moment from 'moment';
import { downloadFile } from 'utils/helpers';
import { toast } from 'components/Toast';

export default function Order() {
  const textColor = useColorModeValue('gray.700', 'white');
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [orderStatus, setOrderStatus] = useState();
  const [searchTitle, setSearchTitle] = useState('');
  const [filterDate, setFilterDate] = useState({
    start: null,
    end: null,
  });
  const { data: orderData, refetch } = useQueryGetListOrder(filter);
  const { data: sourceAddressData } = useQueryGetSourceAddress({ enabled: CookieStorage.isAuthenticated() });
  const { data: returnAddressData } = useQueryGetReturnAddress({ enabled: CookieStorage.isAuthenticated() });
  const getOrdersMutation = useGetOrdersMutation(
    {
      ...filter,
      status: orderStatus?.value,
      searchKeyword: searchTitle,
      ...(filterDate?.start && { start: moment(filterDate?.start).startOf('day').toISOString() }),
      ...(filterDate?.end && { end: moment(filterDate?.end).endOf('day').toISOString() }),
      responseType: 'arraybuffer',
    },
    { responseType: 'arraybuffer' }
  );

  const handleSearch = () => {
    setFilter({
      ...filter,
      status: orderStatus?.value,
      searchKeyword: searchTitle,
      ...(filterDate?.start && { start: moment(filterDate?.start).startOf('day').toISOString() }),
      ...(filterDate?.end && { end: moment(filterDate?.end).endOf('day').toISOString() }),
    });
  };

  const onDownloadTemplate = () => {
    getOrdersMutation.mutate(undefined, {
      onSuccess: response => {
        downloadFile(response, 'order-template');
        toast.showMessageSuccess('Tải mẫu đơn hàng thành công');
      },
      onError: () => {
        toast.showMessageError('Tải mẫu đơn hàng thất bại');
      },
    });
  };

  const handleReset = () => {
    setFilter({
      pageIndex: 0,
      pageSize: 10,
    });
    setFilterDate({
      start: null,
      end: null,
    });
    setOrderStatus(null);
    setSearchTitle('');
  };

  const onChangeDate = type => date => {
    setFilterDate(prev => ({
      ...prev,
      ...(type === 'start' && { end: null }),
      [type]: date,
    }));
  };

  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px', lg: '100px' }}>
      <Card p="16px" mb="24px" bg="#fff">
        <CardHeader p="12px 5px" mb="12px">
          <Flex justifyContent={'space-between'}>
            <Flex direction={'column'}>
              <Flex direction="column" gap={'30px'}>
                <Text fontSize="xl" color={textColor} fontWeight="bold">
                  Đơn hàng
                </Text>
              </Flex>
            </Flex>
            <Flex gap={2} alignItems={'center'} alignSelf={'start'}>
              <Button bg="#3182ce" color="#fff" _hover={{ bg: '#67a1d7' }} isLoading={false} onClick={onDownloadTemplate}>
                Xuất dữ liệu
              </Button>
              {/* <Flex alignItems="center">
                  <input type="file" hidden ref={inputImportRef} onChange={handleImportProduct} />
                  <Button
                    bg="#3182ce"
                    color="#fff"
                    _hover={{ bg: '#67a1d7' }}
                    onClick={() => {
                      inputImportRef?.current?.click();
                    }}
                  >
                    Import sản phẩm
                  </Button>
                </Flex> */}
            </Flex>
          </Flex>
        </CardHeader>
        <Flex justifyContent={'space-between'} alignItems={'end'} gap={4} my={'20px'}>
          <Stack>
            <Flex alignItems={'end'} gap={4} flexWrap={{ base: 'wrap', md: 'nowrap' }}>
              <FormControl>
                <FormLabel marginRight={0}>Tìm kiếm theo mã đơn và tên hội viên</FormLabel>
                <Input value={searchTitle} onChange={e => setSearchTitle(e.target.value)} />
              </FormControl>
              <FormControl>
                <FormLabel marginRight={0}>Tình trạng</FormLabel>
                <Select
                  placeholder="Trạng thái đơn hàng"
                  isClearable
                  menuShouldBlockScroll
                  value={orderStatus}
                  onChange={e => {
                    setOrderStatus(e);
                  }}
                  options={OrderStatusOption}
                ></Select>
              </FormControl>
              <FormControl>
                <Flex mt={2} alignItems={'center'}>
                  <FormControl display="flex" flexDirection="column" maxW="300px" mr="12px">
                    <FormLabel marginRight={0} fontSize={14} isTruncated>
                      Ngày bắt đầu
                    </FormLabel>
                    <DatePicker
                      styleInput={{
                        minWidth: '300px',
                      }}
                      selectedDate={filterDate.start}
                      onChange={date => onChangeDate('start')(date)}
                    />
                  </FormControl>
                  <FormControl display="flex" flexDirection="column" maxW="300px" mr="12px">
                    <FormLabel marginRight={0} fontSize={14} isTruncated>
                      Ngày kết thúc
                    </FormLabel>
                    <DatePicker
                      styleInput={{
                        minWidth: '300px',
                      }}
                      selectedDate={filterDate.end}
                      minDate={filterDate.start}
                      // maxDate={new Date(formatDate(moment(filter.start).add(6, 'days')))}
                      onChange={date => onChangeDate('end')(date)}
                    />
                  </FormControl>
                </Flex>
              </FormControl>
              <Button maxH="40px" px={8} alignSelf={'end'} onClick={handleReset}>
                <Text fontSize="md" fontWeight="bold" cursor="pointer">
                  Cài lại
                </Text>
              </Button>
              <Button variant="primary" px={8} maxH="40px" alignSelf={'end'} onClick={handleSearch}>
                <Text fontSize="md" fontWeight="bold" cursor="pointer">
                  Tìm kiếm
                </Text>
              </Button>
            </Flex>
          </Stack>
        </Flex>
        <CardBody overflowX="auto">
          <Stack overflow={'auto'}>
            <OrderTable
              sourceAddressData={sourceAddressData}
              returnAddressData={returnAddressData}
              orderData={orderData?.data || []}
              refetch={refetch}
            />
          </Stack>
          <Flex justifyContent={'flex-end'}>
            {!isEmpty(orderData?.data) && (
              <Pagination
                page={orderData?.pagination?.page}
                pageLength={orderData?.pagination?.pageSize}
                totalRecords={orderData?.pagination?.count}
                onPageChange={(page, pageLength) => {
                  setFilter({
                    ...filter,
                    pageSize: pageLength,
                    pageIndex: page - 1,
                  });
                }}
              />
            )}
          </Flex>
        </CardBody>
      </Card>
    </Flex>
  );
}
