import React, { useState, useEffect } from 'react';
import { Button, Flex, Text } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import isEmpty from 'lodash/isEmpty';
import { useHistory, useParams } from 'react-router-dom';
import InputController from 'components/Form/InputController';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import { MissionFormValidate } from 'utils/validation';
import NumericInputController from 'components/Form/NumericInputController';
import { toast } from 'components/Toast';
import { FileImageValid, GameTypesMissionOption, LevelMemberOption, MISSION_TYPE, MissionOption, ROOT_API } from 'constants/common';
import { uploadThumbnailMission, useCreateMission, useQueryGetMissionDetail, useUpdateMission } from 'services/mission';
import SelectController from 'components/Form/SelectController';
import FileSelectController from 'components/Form/FileSelectController';

const checkTypeAbsoluteLevel = value => {
  return [MISSION_TYPE.BET_IN_DAY, MISSION_TYPE.BET_IN_WEEK].some(item => item === value);
};

export default function MissionForm() {
  const params = useParams();
  const { id } = params || {};
  const history = useHistory();
  const [fileSelected, setFileSelected] = useState({
    thumbnail: null,
  });
  const [error, setError] = useState({
    thumbnail: null,
  });

  const { data: missionDetailData } = useQueryGetMissionDetail(id, {
    enabled: !!id,
  });
  const createMissionMutation = useCreateMission();
  const updateMissionMutation = useUpdateMission();

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { isDirty },
  } = useForm({
    resolver: yupResolver(MissionFormValidate),
  });

  useEffect(() => {
    const productDetail = missionDetailData?.data;

    if (!isEmpty(productDetail)) {
      reset({
        title: productDetail.title,
        totalAmountToCollect: productDetail.totalAmountToCollect,
        type: MissionOption.find(item => item.value === productDetail?.type),
        coinCollect: productDetail?.coinCollect,
        level: LevelMemberOption.filter(item => productDetail?.level.includes(item.value)),
        gameTypes: GameTypesMissionOption.filter(option => productDetail?.gameTypes?.includes(Number(option.value))),
        description: productDetail.description,
      });
      productDetail?.thumbnail && setFileSelected({ thumbnail: { filePreview: ROOT_API + '/' + productDetail?.thumbnail } });
    }
  }, [missionDetailData]);

  const onUploadPhoto = async () => {
    const formData = new FormData();

    if (!!fileSelected?.thumbnail?.file) {
      formData.append('thumbnailFile', fileSelected?.thumbnail?.file);

      const response = await uploadThumbnailMission(formData);

      return response?.data;
    }

    return null;
  };

  const onSubmit = async dataForm => {
    const photosPath = await onUploadPhoto();

    const dataSubmit = {
      ...dataForm,
      type: dataForm.type.value,
      level: dataForm?.level?.map(item => item.value),
      gameTypes: dataForm?.gameTypes?.map(item => Number(item.value)),
      ...(photosPath && { thumbnail: photosPath }),
    };

    if (!!id) {
      updateMissionMutation.mutate(
        { ...dataSubmit, id },
        {
          onSuccess: () => {
            toast.showMessageSuccess('Cập nhật nhiệm vụ thành công');
            history.push('/admin/mission');
          },
          onError: () => {
            toast.showMessageError('Cập nhật nhiệm vụ thất bại');
          },
        }
      );

      return;
    }

    createMissionMutation.mutate(dataSubmit, {
      onSuccess: () => {
        toast.showMessageSuccess('Tạo nhiệm vụ thành công');
        history.push('/admin/mission');
      },
      onError: () => {
        toast.showMessageError('Tạo nhiệm vụ thất bại');
      },
    });
  };

  const handleFileSelect = (fieldName, e) => {
    if (e?.target?.files?.[0]) {
      const fileSelected = e.target.files[0];
      const filePreview = URL.createObjectURL(fileSelected);
      const extensionFile = fileSelected?.name?.split('.')?.pop()?.toLowerCase();

      if ([...FileImageValid].includes(extensionFile)) {
        setFileSelected(prev => ({
          ...prev,
          [fieldName]: { file: fileSelected, filePreview },
        }));

        return;
      }

      setFileSelected(prev => ({
        ...prev,
        [fieldName]: null,
      }));
      handleErrorFile(fieldName, 'File chỉ hỗ trợ định dạng .png, .jpeg, .jpg');
    }
  };

  const handleErrorFile = (fieldName, value) => {
    setError(prev => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px' }}>
      <Card overflowX={{ sm: 'scroll', xl: 'hidden' }} pb="0px" bg="white">
        <CardHeader p="6px 0px 22px 0px">
          <Flex alignItems="center" justifyContent="space-between">
            <Text fontSize="xl" fontWeight="bold">
              {`${id ? 'Cập nhật' : 'Tạo'} nhiệm vụ`}
            </Text>
            {/* {!!id && (
              <Flex flexDirection="column" alignItems="center" gap={1}>
                <Text>Phát hành</Text>
                <Switch size="md" isChecked={missionDetailData?.data?.isPublished} onChange={handlePublicProduct} />
              </Flex>
            )} */}
          </Flex>
        </CardHeader>
        <CardBody pb={4}>
          <form>
            <FileSelectController
              label="Icon"
              name="thumbnail"
              imgHeight="150px"
              imgWidth="150px"
              filePreview={fileSelected.thumbnail?.filePreview}
              error={error.thumbnail}
              handleFileSelect={handleFileSelect}
              handleErrorFile={handleErrorFile}
            />
            <InputController control={control} name="title" label="Tên" isRequired styleContainer={{ pt: '4' }} />
            <SelectController
              styleContainer={{ pt: '4' }}
              control={control}
              isRequired
              name="type"
              label="Loại"
              isClearable
              options={MissionOption}
            />
            {checkTypeAbsoluteLevel(watch('type')?.value) && (
              <SelectController
                styleContainer={{ pt: '4' }}
                control={control}
                isRequired={checkTypeAbsoluteLevel(watch('type')?.value)}
                isMulti
                name="gameTypes"
                label="Loại game"
                isClearable
                options={GameTypesMissionOption}
              />
            )}
            <NumericInputController
              control={control}
              name="totalAmountToCollect"
              label="Tổng nạp / Tổng cược"
              isRequired
              styleContainer={{ pt: '4' }}
            />
            <NumericInputController control={control} name="coinCollect" label="Số xu thu thập" isRequired styleContainer={{ pt: '4' }} />
            {checkTypeAbsoluteLevel(watch('type')?.value) && (
              <SelectController
                styleContainer={{ pt: '4' }}
                control={control}
                isRequired={checkTypeAbsoluteLevel(watch('type')?.value)}
                name="level"
                isMulti
                label="Cấp độ hội viên"
                isClearable
                options={LevelMemberOption}
              />
            )}
          </form>
          <Flex pt={6} alignItems="flex-end" justifyContent="flex-end">
            <Button
              onClick={() => {
                history.goBack();
              }}
            >
              Hủy
            </Button>
            <Button
              isDisabled={!fileSelected?.thumbnail?.file && !isDirty}
              colorScheme="blue"
              ml={3}
              isLoading={createMissionMutation.isPending || updateMissionMutation.isPending}
              onClick={handleSubmit(onSubmit)}
            >
              {!!id ? 'Cập nhật ' : 'Tạo'}
            </Button>
          </Flex>
        </CardBody>
      </Card>
    </Flex>
  );
}
