import { useMutation, useQuery } from '@tanstack/react-query';
import { request } from 'utils/request';

export const getCountry = data => request.get('/api/v1/country', data);
export const getCountryByCode = params => request.get(`/api/v1/countries/country-by-code/${params}`);
export const getStateByCountry = params => request.get(`/api/v1/countries/states/${params}`);
export const createShippingFee = params => request.post(`/api/v1/countries/fee-for-country`, params);
export const postStateTax = data => request.post('/api/v1/countries/tax-for-state', data);
export const getListOrder = params => request.get('/api/v1/orders-list', { params });
export const getListOrderExport = (params, headers) => request.get('/api/v1/orders-list?isExport=true', { params, ...headers });
export const getShipOrderDetail = id => request.get(`/api/v1/ships/${id}`);
export const getListShipOrder = params => request.get('/api/v1/ships/list', { params });
export const getListShipOrderExport = (params, headers) => request.get('/api/v1/ships/list?isExport=true', { params, ...headers });
export const createShipOrder = data => request.post('/api/v1/orders/ship/create', data);
export const cancelShipOrder = data => request.post('/api/v1/ships/cancel', data);
export const updateAddressShip = data => request.post('/api/v1/ships/update-address', data);
export const changeStatusOrder = data => request.post('/api/v1/orders/change-status', data);
export const deleteOrder = data => request.post('/api/v1/orders/delete', data);

// Mutation
export const useGetCountryByCodeMutation = () => useMutation({ mutationFn: getCountryByCode });
export const useGetOrdersMutation = (params, headers) => useMutation({ mutationFn: () => getListOrderExport(params, headers) });
export const useGetOrderShipMutation = (params, headers) => useMutation({ mutationFn: () => getListShipOrderExport(params, headers) });
export const useGetStateByCountryMutation = () => useMutation({ mutationFn: getStateByCountry });
export const useCreateShippingFeeMutation = () => useMutation({ mutationFn: createShippingFee });
export const useStateTaxMutation = () => useMutation({ mutationFn: postStateTax });
export const useCreateShipOrder = () => useMutation({ mutationFn: createShipOrder });
export const useCancelShipOrderMutation = () => useMutation({ mutationFn: cancelShipOrder });
export const useUpdateAddressShipOrderMutation = () => useMutation({ mutationFn: updateAddressShip });
export const useChangeStatusOrderMutation = () => useMutation({ mutationFn: changeStatusOrder });
export const useDeleteOrderMutation = () => useMutation({ mutationFn: deleteOrder });

// Query
export const useQueryGetCountry = (params = {}, options = {}) =>
  useQuery({ queryKey: ['getCountry', params], queryFn: () => getCountry(params), ...options });
export const useQueryGetListOrder = (params, options = {}) =>
  useQuery({ queryKey: ['getListOrder', params], queryFn: () => getListOrder(params), ...options });
export const useQueryGetListShipOrder = (params, options = {}) =>
  useQuery({ queryKey: ['getListShipOrder', params], queryFn: () => getListShipOrder(params), ...options });
export const useQueryShipOrderDetail = (id, options = {}) =>
  useQuery({ queryKey: ['getShipOrderDetail', id], queryFn: () => getShipOrderDetail(id), ...options });
