import { useQuery, useMutation } from '@tanstack/react-query';
import { request } from 'utils/request';

export const getGetStatusSystem = () => request.get('/api/v1/setting-system');
export const getHistoryChangeStatusSystem = params => request.get('/api/v1/setting-system/history', { params });
export const createSystemMaintenance = () => request.post('/api/v1/setting-system');
export const changeStatusSystem = data => request.post('api/v1/setting-system/change-status', data);

//Mutation
export const useCreateSystemMaintenance = () => useMutation({ mutationFn: createSystemMaintenance });
export const useChangeStatusSystem = () => useMutation({ mutationFn: changeStatusSystem });
//Query
export const useGetStatusSystem = () => useQuery({ queryKey: 'GET_STATUS_SYSTEM', queryFn: () => getGetStatusSystem() });
export const useGetHistoryChangeStatusSystem = params =>
  useQuery({ queryKey: ['GET_HISTORY_STATUS_SYSTEM', params], queryFn: () => getHistoryChangeStatusSystem(params) });
