import React, { useEffect } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Flex,
  Text,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import isEmpty from 'lodash/isEmpty';
import { useHistory } from 'react-router-dom';
import { StatusOrderFormValidate } from 'utils/validation';
import { toast } from 'components/Toast';
import { ModalType, OrderStatusOption } from 'constants/common';
import { useChangeStatusOrderMutation } from 'services/shipping';
import SelectController from 'components/Form/SelectController';

export default function ChangeStatusOrder({ isOpen, orderDetail, onClose, refetch }) {
  const history = useHistory();
  const cancelRef = React.useRef();
  const changeStatusOrder = useChangeStatusOrderMutation();

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { isDirty },
  } = useForm({
    resolver: yupResolver(StatusOrderFormValidate),
  });

  useEffect(() => {
    if (!isEmpty(orderDetail)) {
      reset({
        status: OrderStatusOption.find(item => item.value === orderDetail?.status),
      });
    }
  }, [orderDetail]);

  const onSubmit = async dataForm => {
    changeStatusOrder.mutate(
      { orderNo: orderDetail?.orderNo, status: dataForm?.status?.value },
      {
        onSuccess: res => {
          refetch();
          onClose();
          toast.showMessageSuccess('Cập nhật trạng thái đơn hang thành công');
        },
      }
    );
  };

  return (
    <AlertDialog
      motionPreset="slideInBottom"
      leastDestructiveRef={cancelRef}
      onClose={() => {
        onClose(ModalType.Preview);
      }}
      isOpen={isOpen}
      isCentered
    >
      <AlertDialogOverlay />
      <AlertDialogContent maxW={'800px'}>
        <AlertDialogHeader textTransform="uppercase">{'Cập nhập trạng thái đơn hàng'}</AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>
          <Flex direction="column" pb={'15px'} gap={4}>
            <Flex flexDirection="column">
              <Text fontWeight={700}>
                Trạng thái đơn hàng hiện tại: {OrderStatusOption.find(item => item.value === orderDetail?.status)?.label}
              </Text>
            </Flex>
            <form>
              <SelectController
                label="Trạng thái đơn hàng"
                name="status"
                placeholder="Chọn trạng thái đơn hàng"
                control={control}
                options={OrderStatusOption}
                isRequired
                styleContainer={{ pt: '4' }}
              />
            </form>
            <Flex pt={6} alignItems="flex-end" justifyContent="flex-end">
              <Button
                onClick={() => {
                  onClose();
                }}
              >
                Hủy
              </Button>
              <Button
                isDisabled={!isDirty}
                colorScheme="blue"
                ml={3}
                isLoading={changeStatusOrder.isPending}
                onClick={handleSubmit(onSubmit)}
              >
                {'Cập nhập'}
              </Button>
            </Flex>
          </Flex>
        </AlertDialogBody>
      </AlertDialogContent>
    </AlertDialog>
  );
}
