import React from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Text,
  Flex,
  Card,
  CardBody,
  List,
  Grid,
  GridItem,
  Image,
} from '@chakra-ui/react';
import { ModalType, ROOT_API } from 'constants/common';

const ReviewModal = ({ isOpen, onClose, reviewDetailData }) => {
  const cancelRef = React.useRef();

  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onClose(ModalType.Preview);
        }}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent maxW={'800px'}>
          <AlertDialogHeader textTransform="uppercase">Chi tiết đánh giá đơn hàng</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <Flex direction="column">
              <Card overflowX={{ sm: 'scroll', xl: 'hidden' }} pb="0px" bg="white">
                <CardBody p={0}>
                  <List spacing={3}>
                    <Flex gap={2}>
                      <Text fontWeight={600} minW={'150px'}>
                        Đánh giá:
                      </Text>
                      <Text>{reviewDetailData?.vote}</Text>
                    </Flex>
                    <Flex gap={2}>
                      <Text fontWeight={600} minW={'150px'}>
                        Nhận xét:
                      </Text>
                      <Text>{reviewDetailData?.comment}</Text>
                    </Flex>
                    <Flex flexDirection="column" gap={2}>
                      <Text fontWeight={600} minW={'150px'}>
                        Hình ảnh:
                      </Text>
                      <Grid templateColumns="repeat(5, 1fr)" gap={6} pt={5}>
                        {reviewDetailData?.images?.map((file, index) => (
                          <GridItem w={'100%'} key={index} position="relative">
                            <Image src={ROOT_API + '/' + file} w="full" h="full" objectFit="contain" />
                          </GridItem>
                        ))}
                      </Grid>
                    </Flex>
                  </List>
                </CardBody>
              </Card>
            </Flex>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Flex pt={6} alignItems="flex-end" justifyContent="flex-end">
              <Button
                onClick={() => {
                  onClose(ModalType.Preview);
                }}
              >
                Đóng
              </Button>
            </Flex>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default ReviewModal;
