import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { CookieStorage } from 'utils/cookie-storage';
import { useHistory } from 'react-router-dom';
import { useMemo } from 'react';
import {
  GAME_TYPE_MISSION,
  GameTypesMissionOption,
  LevelMemberKkOptionFilter,
  LevelMemberOptionFilter,
  MissionOption,
  ModalType,
} from 'constants/common';
// import CreateCategoryModal from './components/CreateCategoryModal';
import Pagination from 'components/Pagination/Pagination';
import { useQueryGetListMemberMissions } from 'services/purchase-history';
import { Select } from 'chakra-react-select';
import HistoryTable from './HistoryTable';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import MissionTable from './MissionTable';
import DatePicker from 'components/DatePicker/DatePicker';
import moment from 'moment';
import { useExportCoinTemplateMemberMutation } from 'services/user';
import { downloadFile } from 'utils/helpers';

function MemberMissions() {
  const isLoggedIn = CookieStorage.isAuthenticated();
  const textColor = useColorModeValue('gray.700', 'white');
  const history = useHistory();
  const [sizeEditing, setSizeEditing] = useState(null);
  const [idMember, setIdMember] = useState();
  const [searchTitle, setSearchTitle] = useState('');
  const [kkLevel, setKkLevel] = useState();
  const [type, setType] = useState();
  const [gameType, setGameType] = useState();
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 20,
  });
  const [filterDate, setFilterDate] = useState({
    start: null,
    end: null,
  });
  const params = useParams();
  const { id } = params || {};

  useEffect(() => {
    if (!isLoggedIn) {
      return history.push('/auth/sign-in');
    }
  }, [isLoggedIn, history]);

  const { data: missions, refetch } = useQueryGetListMemberMissions(
    { ...filter, searchKeyword: filter.searchKeyword, memberId: id },
    { enabled: isLoggedIn }
  );
  const exportTemplateCoinMutation = useExportCoinTemplateMemberMutation({
    ...filter,
    isExport: true,
    responseType: 'arraybuffer',
    searchKeyword: searchTitle,
    kkLevel: kkLevel?.value,
    gameType: gameType?.value,
    memberId: id,
    type: type?.value,
    ...(filterDate?.start && { start: moment(filterDate?.start).startOf('day').toISOString() }),
    ...(filterDate?.end && { end: moment(filterDate?.end).endOf('day').toISOString() }),
  }, {
    responseType: 'arraybuffer',
  })

  const handleUpdateItem = (size, modalType) => {
    openModal?.[modalType]?.();
    setSizeEditing(size);
  };

  const handleCloseModal = modalType => {
    closeModal?.[modalType]?.();
    setSizeEditing(null);
  };

  const handleSearch = () => {
    setFilter({
      ...filter,
      searchKeyword: searchTitle,
      kkLevel: kkLevel?.value,
      gameType: gameType?.value,
      type: type?.value,
      ...(filterDate?.start && { start: moment(filterDate?.start).startOf('day').toISOString() }),
      ...(filterDate?.end && { end: moment(filterDate?.end).endOf('day').toISOString() }),
    });
  };
  const handleReset = () => {
    setFilter({
      pageIndex: 0,
      pageSize: 10,
    });
    setKkLevel(null);
    setGameType(null);
    setType(null);
    setSearchTitle('');
    setFilterDate({
      start: null,
      end: null,
    });
  };

  const onChangeDate = type => date => {
    setFilterDate(prev => ({
      ...prev,
      ...(type === 'start' && { end: null }),
      [type]: date,
    }));
  };

  const handleShowMemberDetail = (idMember, modalType) => {
    openModal?.[modalType]?.();
    setIdMember(idMember);
  };

  const onDownloadTemplate = () => {
    exportTemplateCoinMutation.mutate(undefined, {
      onSuccess: response => {
        downloadFile(response, 'member-coin-template');
        toast.showMessageSuccess('Tải mẫu lịch sử nhận xu thành công');
      },
      onError: () => {
        toast.showMessageError('Tải mẫu lịch sử nhận xu thất bại');
      },
    });
  };
  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px', lg: '100px' }}>
      <Card p="16px" mb="24px" bg="#fff">
        <CardHeader p="12px 5px" mb="12px">
          <Flex justifyContent={'space-between'} w={'full'}>
            <Flex direction={'column'} w={'full'}>
              <Flex gap={'30px'} justifyContent={"space-between"}>
                <Text fontSize="xl" color={textColor} fontWeight="bold">
                  Lịch sử nhận xu
                </Text>
                <Button bg="#3182ce" color="#fff" _hover={{ bg: '#67a1d7' }} isLoading={false} 
                onClick={onDownloadTemplate}
                >
                  Xuất dữ liệu
                </Button>
              </Flex>
              <Flex alignItems={'center'} gap={'20px'} flexWrap={{ base: 'wrap', md: 'nowrap' }} mt={3}>
                <Stack width={'full'}>
                  <Flex alignItems={'center'} gap={'20px'} flexWrap={'wrap'}>
                    <FormControl w={{ base: 'full', sm: '300px' }}>
                      <FormLabel>Tìm kiếm nhiệm vụ</FormLabel>
                      <Input value={searchTitle} onChange={e => setSearchTitle(e.target.value)} />
                    </FormControl>
                    <FormControl w={{ base: 'full', sm: '300px' }}>
                      <FormLabel h={'21px'}>Cấp độ HTTĐ</FormLabel>
                      <Select
                        placeholder="Chọn cấp độ HTTĐ"
                        isClearable
                        menuShouldBlockScroll
                        value={kkLevel}
                        onChange={e => {
                          setKkLevel(e);
                        }}
                        options={LevelMemberKkOptionFilter}
                      ></Select>
                    </FormControl>
                    <FormControl w={{ base: 'full', sm: '300px' }}>
                      <FormLabel h={'21px'}>Loại game</FormLabel>
                      <Select
                        placeholder="Chọn loại game"
                        isClearable
                        menuShouldBlockScroll
                        value={gameType}
                        onChange={e => {
                          setGameType(e);
                        }}
                        options={GameTypesMissionOption}
                      ></Select>
                    </FormControl>
                    <FormControl w={{ base: 'full', sm: '300px' }}>
                      <FormLabel h={'21px'}>Loại nhiệm vụ</FormLabel>
                      <Select
                        placeholder="Chọn loại nhiệm vụ"
                        isClearable
                        menuShouldBlockScroll
                        value={type}
                        onChange={e => {
                          setType(e);
                        }}
                        options={MissionOption}
                      ></Select>
                    </FormControl>
                    <FormControl display="flex" flexDirection="column" maxW="300px">
                      <FormLabel marginRight={0} fontSize={14} isTruncated>
                        Ngày bắt đầu
                      </FormLabel>
                      <DatePicker
                        styleInput={{
                          minWidth: '300px',
                        }}
                        selectedDate={filterDate.start}
                        onChange={date => onChangeDate('start')(date)}
                      />
                    </FormControl>
                    <FormControl display="flex" flexDirection="column" maxW="300px">
                      <FormLabel marginRight={0} fontSize={14} isTruncated>
                        Ngày kết thúc
                      </FormLabel>
                      <DatePicker
                        styleInput={{
                          minWidth: '300px',
                        }}
                        selectedDate={filterDate.end}
                        minDate={filterDate.start}
                        // maxDate={new Date(formatDate(moment(filter.start).add(6, 'days')))}
                        onChange={date => onChangeDate('end')(date)}
                      />
                    </FormControl>
                  </Flex>
                  <Flex alignItems={'center'} gap={'20px'} flexWrap={{ base: 'wrap', md: 'nowrap' }} mt={3}>
                    <Button px={10} maxH="40px" alignSelf={'end'} onClick={handleReset}>
                      <Text fontSize="md" fontWeight="bold" cursor="pointer">
                        Cài lại
                      </Text>
                    </Button>
                    <Button variant="primary" px={10} maxH="40px" alignSelf={'end'} onClick={handleSearch}>
                      <Text fontSize="md" fontWeight="bold" cursor="pointer">
                        Tìm kiếm
                      </Text>
                    </Button>
                  </Flex>
                </Stack>
              </Flex>
            </Flex>
          </Flex>
        </CardHeader>
        <CardBody overflowX="auto">
          <Stack overflow={'auto'}>
            <MissionTable
              tableData={missions?.data || []}
              handleUpdateCategory={handleUpdateItem}
              refetch={refetch}
              handleShowMemberDetail={handleShowMemberDetail}
            />
          </Stack>
          <Flex justifyContent={'flex-end'}>
            <Pagination
              page={missions?.pagination?.page}
              pageLength={missions?.pagination?.pageSize}
              totalRecords={missions?.pagination?.count}
              onPageChange={(page, pageLength) => {
                setFilter({
                  ...filter,
                  pageSize: pageLength,
                  pageIndex: page - 1,
                });
              }}
            />
          </Flex>
        </CardBody>
      </Card>
    </Flex>
  );
}

export default MemberMissions;
