import React, { useState, useEffect, useRef } from 'react';
import { Button, Flex, Text, useColorModeValue, Switch } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import isEmpty from 'lodash/isEmpty';
import { useHistory, useParams } from 'react-router-dom';
import InputController from 'components/Form/InputController';
import EditorController from 'components/Form/EditorController';
import { SystemContentValidate } from 'utils/validation';

import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import SelectController from 'components/Form/SelectController';
import { FileImageValid, PostType, PostTypeOptionAuthor, PostTypeOption, ROOT_API } from 'constants/common';
import FileSelectController from 'components/Form/FileSelectController';
import { uploadThumbnailSystem, useCreateSystemContent, useQueryGetContentDetail, useUpdateSystemContent } from 'services/system-content';
import { toast } from 'components/Toast';
import { formatImageUrl } from 'utils/url';
import { authorizationOption } from 'components/AuthorizationComponentWrapper/AuthorizationComponentWrapper';
import { useUserState } from 'context/UserContext';

export default function PostForm() {
  const [fileSelected, setFileSelected] = useState({
    thumbnail: null,
    thumbnail_mobile: null,
  });
  const [error, setError] = useState({
    thumbnail: null,
    thumbnail_mobile: null,
  });
  const textColor = useColorModeValue('gray.700', 'white');
  const params = useParams();
  const { id } = params || {};
  const history = useHistory();
  const { userInfo } = useUserState()

  const { data: contentDetailData } = useQueryGetContentDetail(id, {
    enabled: !!id,
  });
  const createSystemContentMutation = useCreateSystemContent();
  const updateSystemContentMutation = useUpdateSystemContent();
  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(SystemContentValidate),
    defaultValues: {
      type: undefined,
      order: '',
      title: '',
      body: '',
    },
  });
  const watchType = watch('type');

  useEffect(() => {
    const data = contentDetailData?.data;

    if (!isEmpty(data)) {
      reset({
        title: data.title,
        type: PostTypeOption.find(item => item.value === data?.type),
        body: data.body,
        order: data.order,
      });
      data?.thumbnailUrl &&
        setFileSelected({
          thumbnail: { filePreview: formatImageUrl(data?.thumbnailUrl) },
          ...(data?.thumbnailUrlH5 && { thumbnail_mobile: { filePreview: formatImageUrl(data?.thumbnailUrlH5) } }),
        });
      data?.thumbnailUrlH5 &&
        setFileSelected({
          thumbnail_mobile: { filePreview: formatImageUrl(data?.thumbnailUrlH5) },
          ...(data?.thumbnailUrl && { thumbnail: { filePreview: formatImageUrl(data?.thumbnailUrl) } }),
        });
    }
  }, [contentDetailData]);

  const handleSuccess = () => {
    toast.showMessageSuccess(`${!!id ? 'Cập nhật' : 'Tạo'} nội dung thành công`);
    history.push('/admin/setting-system');
  };

  const onUploadPhoto = async () => {
    const formData = new FormData();
    let storePhoto = {
      thumbnail: fileSelected?.thumbnail?.filePreview?.replace(ROOT_API + "/", "") || fileSelected?.thumbnail,
      thumbnail_mobile: fileSelected?.thumbnail_mobile?.filePreview?.replace(ROOT_API + "/", "") || fileSelected?.thumbnail_mobile,
    };
    
    
    if (!!fileSelected?.thumbnail?.file) {
      formData.append('thumbnailFile', fileSelected?.thumbnail?.file);
      
      const response = await uploadThumbnailSystem(formData);
      storePhoto.thumbnail = response?.data;
    }
    
    if (!!fileSelected?.thumbnail_mobile?.file) {
      formData.delete('thumbnailFile');
      formData.append('thumbnailFile', fileSelected?.thumbnail_mobile?.file);
      
      const response = await uploadThumbnailSystem(formData);
      storePhoto.thumbnail_mobile = response?.data;
    }
    return storePhoto;
  };

  const validate = () => {
    const errorArray = Object.values(error);

    return !isEmpty(errorArray?.filter(item => !!item));
  };

  const onSubmit = async dataForm => {
    const photosPath = await onUploadPhoto();

    if (dataForm?.type?.value === PostType.Banner && !photosPath.thumbnail) {
      setError({ ...error, thumbnail: 'Vui lòng tải ảnh lên' });
      return;
    }

    if (dataForm?.type?.value === PostType.Banner && !photosPath.thumbnail_mobile) {
      setError({ ...error, thumbnail_mobile: 'Vui lòng tải ảnh lên' });
      return;
    }

    const dataSubmit = {
      ...dataForm,
      type: dataForm.type.value,
      ...(photosPath.thumbnail && { thumbnailUrl: photosPath.thumbnail }),
      ...(photosPath.thumbnail_mobile && { thumbnailUrlH5: photosPath.thumbnail_mobile }),
    };

    if (!!id) {
      updateSystemContentMutation.mutate(
        { ...dataSubmit, id },
        {
          onSuccess: () => {
            handleSuccess();
          },
        }
      );
      return;
    }

    createSystemContentMutation.mutate(dataSubmit, {
      onSuccess: () => {
        handleSuccess();
      },
    });
  };

  const handleFileSelect = (fieldName, e) => {
    if (e?.target?.files?.[0]) {
      const fileSelected = e.target.files[0];
      const filePreview = URL.createObjectURL(fileSelected);
      const extensionFile = fileSelected?.name?.split('.')?.pop()?.toLowerCase();

      if ([...FileImageValid].includes(extensionFile)) {
        setFileSelected(prev => ({
          ...prev,
          [fieldName]: { file: fileSelected, filePreview },
        }));

        return;
      }

      setFileSelected(prev => ({
        ...prev,
        [fieldName]: null,
      }));
      handleErrorFile(fieldName, 'File chỉ hỗ trợ định dạng .png, .jpeg, .jpg');
    }
  };

  const handleErrorFile = (fieldName, value) => {
    setError(prev => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px' }}>
      <Card overflowX={{ sm: 'scroll', xl: 'hidden' }} pb="0px" bg="white">
        <CardHeader p="6px 0px 22px 0px">
          <Flex justifyContent="space-between">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              {`${id ? 'Cập nhật' : 'Tạo'} nội dung`}
            </Text>
            {/* <Switch size="md" isChecked={isPublic} onChange={e => handlePublicPost(e.target.checked)} /> */}
          </Flex>
        </CardHeader>
        <CardBody pb={4}>
          <form>
            <SelectController
              control={control}
              name="type"
              label="Loại"
              isRequired
              options={PostTypeOptionAuthor.filter(item => authorizationOption(item.value, false, userInfo?.permissions, item.title))}
              onChange={e => {
                setFileSelected({ thumbnail: null });
              }}
            />
            {watchType?.value === PostType.Banner && (
              <>
                <FileSelectController
                  label="Banner PC"
                  name="thumbnail"
                  imgWidth="100vw"
                  filePreview={fileSelected.thumbnail?.filePreview}
                  error={error.thumbnail}
                  isRequired
                  // showChangeBtn={missionDetailData?.data ? false : true}
                  // showChooseBtn={missionDetailData?.data ? false : true}
                  handleFileSelect={handleFileSelect}
                  handleErrorFile={handleErrorFile}
                />
                <FileSelectController
                  label="Banner Mobile"
                  name="thumbnail_mobile"
                  imgWidth="100vw"
                  filePreview={fileSelected.thumbnail_mobile?.filePreview}
                  error={error.thumbnail_mobile}
                  isRequired
                  // showChangeBtn={missionDetailData?.data ? false : true}
                  // showChooseBtn={missionDetailData?.data ? false : true}
                  handleFileSelect={handleFileSelect}
                  handleErrorFile={handleErrorFile}
                />
                <InputController control={control} name="order" label="Số thứ tự" isRequired styleContainer={{ pt: '4' }} />
              </>
            )}
            {watchType?.value !== PostType.Banner && (
              <>
                <InputController control={control} name="title" label="Tiêu đề" isRequired styleContainer={{ pt: '4' }} />
                <EditorController
                  styleContainer={{ pt: '4' }}
                  control={control}
                  name="body"
                  label="Nội dung"
                  isRequired
                  // uploadApiUrl={API_ROUTES.UPLOAD_IMAGE}
                />
              </>
            )}
          </form>
          <Flex pt={4} alignItems="flex-end" justifyContent="flex-end">
            <Button
              onClick={() => {
                history.goBack();
              }}
            >
              Hủy
            </Button>
            <Button
              colorScheme="blue"
              ml={3}
              isLoading={createSystemContentMutation.isPending || updateSystemContentMutation.isPending}
              onClick={handleSubmit(onSubmit)}
            >
              {!!id ? 'Cập nhật' : 'Tạo'}
            </Button>
          </Flex>
        </CardBody>
      </Card>
    </Flex>
  );
}
