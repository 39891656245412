import { useState } from 'react';
import { Button, Flex, Stack, Text, useColorModeValue, FormControl, FormLabel, Input } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import Pagination from 'components/Pagination/Pagination';
import ProductTable from './components/Table';
import { useQueryGetMission } from 'services/mission';
import { Select } from 'chakra-react-select';
import { LevelMemberOption, MissionOption } from 'constants/common';
import AuthorizationComponentWrapper from 'components/AuthorizationComponentWrapper/AuthorizationComponentWrapper';
import { useUserState } from 'context/UserContext';

export default function Mission() {
  const history = useHistory();
  const textColor = useColorModeValue('gray.700', 'white');
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [searchTitle, setSearchTitle] = useState('');
  const [level, setLevel] = useState();
  const [type, setType] = useState();
  const { userInfo } = useUserState();

  const { data: missions, refetch } = useQueryGetMission(filter);

  const handleSearch = () => {
    setFilter({
      ...filter,
      level: level?.value,
      type: type?.value,
      searchKeyword: searchTitle,
    });
  };

  const handleReset = () => {
    setSearchTitle('');
    setFilter({
      pageIndex: 0,
      pageSize: 10,
    });
    setLevel(null);
    setType(null);
  };

  return (
    <>
      <Flex direction="column" pt={{ base: '120px', md: '75px', lg: '100px' }}>
        <Card p="16px" mb="24px" bg="#fff">
          <CardHeader p="12px 5px" mb="12px">
            <Flex justifyContent={'space-between'}>
              <Flex direction={'column'}>
                <Flex direction="column" gap={'30px'}>
                  <Text fontSize="xl" color={textColor} fontWeight="bold">
                    Nhiệm vụ
                  </Text>
                </Flex>
              </Flex>
              <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'UPDATE_MISSION'}>
                <Flex gap={2}>
                  <Button
                    bg="#3182ce"
                    color="#fff"
                    _hover={{ bg: '#67a1d7' }}
                    onClick={() => {
                      history.push('/admin/mission-create');
                    }}
                  >
                    <Text fontSize="md" fontWeight="bold" cursor="pointer">
                      Thêm
                    </Text>
                  </Button>
                </Flex>
              </AuthorizationComponentWrapper>
            </Flex>
            <Flex justifyContent={'space-between'} alignItems={'end'} gap={'20px'} mt={'20px'}>
              <Stack>
                <Flex alignItems={'center'} gap={'20px'} flexWrap={'wrap'}>
                  <FormControl w={'300px'}>
                    <FormLabel>Tìm kiếm nhiệm vụ</FormLabel>
                    <Input value={searchTitle} onChange={e => setSearchTitle(e.target.value)} />
                  </FormControl>
                  <FormControl w={'250px'}>
                    <FormLabel h={'21px'}>Thể loại</FormLabel>
                    <Select
                      placeholder="Chọn thể loại"
                      isClearable
                      menuShouldBlockScroll
                      value={type}
                      onChange={e => {
                        setType(e);
                      }}
                      options={MissionOption}
                    ></Select>
                  </FormControl>
                  <FormControl w={'250px'}>
                    <FormLabel h={'21px'}>Cấp độ</FormLabel>
                    <Select
                      placeholder="Chọn cấp độ"
                      isClearable
                      menuShouldBlockScroll
                      value={level}
                      onChange={e => {
                        setLevel(e);
                      }}
                      options={LevelMemberOption}
                    ></Select>
                  </FormControl>
                  <Button variant="primary" maxH="40px" alignSelf={'end'} onClick={handleSearch}>
                    <Text fontSize="md" fontWeight="bold" cursor="pointer">
                      Tìm kiếm
                    </Text>
                  </Button>
                  <Button maxH="40px" alignSelf={'end'} onClick={handleReset}>
                    <Text fontSize="md" fontWeight="bold" cursor="pointer">
                      Đặt lại
                    </Text>
                  </Button>
                </Flex>
              </Stack>
            </Flex>
          </CardHeader>
          <CardBody overflowX="auto">
            <Stack overflow={'auto'}>
              <ProductTable productsData={missions?.data || []} refetch={refetch} />
            </Stack>
            <Flex justifyContent={'flex-end'}>
              {!isEmpty(missions?.data) && (
                <Pagination
                  page={missions?.pagination?.page}
                  pageLength={missions?.pagination?.pageSize}
                  totalRecords={missions?.pagination?.count}
                  onPageChange={(page, pageLength) => {
                    setFilter({
                      ...filter,
                      pageSize: pageLength,
                      pageIndex: page - 1,
                    });
                  }}
                />
              )}
            </Flex>
          </CardBody>
        </Card>
      </Flex>
      {/* {isCreateModalOpen && (
          <CreateSizeModal sizeDetail={sizeEditing} isOpen={isCreateModalOpen} onClose={handelCloseModal} refetch={refetch} />
        )} */}
    </>
  );
}
